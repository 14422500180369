import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

interface AiResponse {
  provisional_diagnosis_response: string;
}

@Injectable({
  providedIn: 'root'
})
export class PatientRegisterService {
  private reloadGridSubject: BehaviorSubject<any>;
  public reloadGrid: Observable<any>;
  private caseHistoryData = new BehaviorSubject('');
  public getCaseHistoryPayload$ = this.caseHistoryData.asObservable();

  private patientHistory = new BehaviorSubject('');
  public getpatientHistory = this.patientHistory.asObservable();

  constructor(private http: HttpClient) {
    this.reloadGridSubject = new BehaviorSubject<any>(false);
    this.reloadGrid = this.reloadGridSubject.asObservable();
  }

  setpatientHistory(newData: any): void {
    this.patientHistory.next(newData);
  }

  caseHistoryPayload(newData: any): void {
    this.caseHistoryData.next(newData);
  }

  getGenogram(payload){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/download/genogram`, payload,
      { headers, responseType: 'blob' as 'json' })

  }

  addParticipantDetails(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/addParticipantsInVc`, payload)
  }

  getParticipantDetails(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getParticipantsInVc`, payload)
  }

  addPatientDetails(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/register`, payload)
  }

  getListVideoRecording(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/telemedicine/listRecordings`, payload)
  }

  addPrecautionDetails(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/addPatientPrecaution`, payload)
  }

  addPrescriptionData(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/addPatientPrescription`, payload)
  }

  getRoleName() {
    return this.http.get(`${environment.admin_prefix_url}/user/getReferenceDoctors`)
  }


  updatePrescriptionData(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientPrescription`, payload)
  }

  updatePrecautionDetails(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientPrecaution`, payload)
  }

  updatePatientDetails(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/update`, payload)
  }


  addPatientBuddy(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/addPatientBuddy`, payload)
  }

  updatePatientBuddy(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientBuddy`, payload)
  }

  addPatientConsultation(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/addPatientConsultation`, payload)
  }

  updatePatientConsultation(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientConsultation`, payload)
  }

  getPatientMaster() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/patientRegister`)
  }



  // Form 5 -- Chief Complaints
  addPatientChiefComplaints(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/addPatientComplaint`, payload)
  }

  updatePatientChiefComplaints(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientComplaint`, payload)
  }

  downloadprescription(payload) {
    const requestOptions: Object = {
      responseType: 'blob' as 'blob'
    };
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/downloadPrescription`, payload, requestOptions)
  }

  downloadCaseSummary(payload) {
    const requestOptions: Object = {
      responseType: 'blob' as 'blob'
    };
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/downloadCaseSummary`, payload, requestOptions)
  }

  downloadDiagnosisFormulation(payload) {
    const requestOptions: Object = {
      responseType: 'blob' as 'blob'
    };
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/downloadDignosisFormulation`, payload, requestOptions)
  }

  updatePatientHistroy(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientHistory`, payload)
  }

  printPrescription(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/printPrescription`, payload)
  }
  printCasesummary(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/printCaseSummary`, payload)
  }
  printDiagnosisFormulation(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/printDignosisFormulation`, payload)
  }

  getPatientGridDetails(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getpatientGrid`, payload)
  }

  //search by Mobile number
  findbyMobileNumber(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/findByMobile`, payload)
  }
  findbyMobileNumberRppViewList(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/findALLPatientRppByMobile`, payload)
  }

  findByMobileNumberAdmin(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/findALLPatientOpdByMobile`,payload)
  }


  addMSEAppearance(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/addPatientMSEAppearanceBehaviour`, payload)
  }

  updateMSEAppearance(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientMSEAppearanceBehaviour`, payload)
  }

  addMSEPsychomotor(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/addPatientMSEPsychomotorSound`, payload)
  }

  updateMSEPsychomotor(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientMSEPsychomotorSound`, payload)
  }

  addThoughtPossessionMood(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/addPatientMSEThoughtPossesionMood`, payload)
  }

  updateThoughtPossessionMood(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientMSEThoughtPossessionMood`, payload)
  }

  addPerceptionCognitive(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/addPatientMSEPerceptionCognitive`, payload)
  }

  updatePerceptionCognitive(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientMSEPerceptionCognitive`, payload)
  }

  addIntelligenceJudgementInsight(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/addPatientMSEIntelligenceJudgementInsight`, payload)
  }

  updateIntelligenceJudgementInsight(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientMSEIntelligenceJudgementInsight`, payload)
  }

  addPatientProvisionDiagnosisTreatment(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/addPatientProvisionDiagnosisTreatment`, payload)
  }

  updatePatientProvisionDiagnosisTreatment(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientProvisionDiagnosisTreatment`, payload)
  }

  addPatientTreatment(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/addPatientTreatment`, payload)
  }

  updatePatientTreatment(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientTreatment`, payload)
  }

  addExpressConsultation(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/addPatientExpressConsultation`, payload)
  }

  updateExpressConsultation(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientExpressConsultation`, payload)
  }

  shareExpressConsultation(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/sharePrescription`, payload)
  }

  // Get Apis
  getPatientRegistration(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getPatientDetails`, payload)
  }

  getCaseHistoryBox(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getCaseHistoryBox`, payload)
  }

  getMSEHistoryBox(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getCaseMseBox`, payload)
  }

  getPdHistoryBox(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getCasePdBox`, payload)
  }

  getPatientBuddy(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getPatientBuddy`, payload)
  }

  getPatientComplaint(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getPatientComplaint`, payload)
  }

  getPatientHistory(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getPatientHistory`, payload)
  }

  getPatientConsultationHistory(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getConsultationHistory`, payload)
  }

  getPatientPrecaution(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getPatientPrecaution`, payload)
  }

  getPatientPrescription(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getPatientPrescription`, payload)
  }

  getPatientConsultationByPatientId(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getPatientConsultationByPatientId`, payload)
  }

  // Get Consultations
  getPatientConsultation(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getPatientConsultation`, payload)
  }

  getPatientMSEAppearanceBehaviour(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getPatientMSEAppearanceBehaviour`, payload)
  }

  getPatientMSEPsychomotorSound(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getPatientMSEPsychomotorSound`, payload)
  }

  getPatientMSEThoughtPossesionMood(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getPatientMSEThoughtPossesionMood`, payload)
  }

  getupdatedCaseHistory(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatedCaseHistory`, payload)
  }

  getPatientMSEPerceptionCognitive(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getPatientMSEPerceptionCognitive`, payload)
  }

  getPatientProvisionDiagnosisTreatment(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getPatientProvisionDiagnosisTreatment`, payload)
  }

  getPatientTreatment(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getPatientTreatment`, payload)
  }

  getPatientMSEIntelligenceJudgementInsight(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getPatientMSEIntelligenceJudgementInsight`, payload)
  }

  generateZoomLink(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/payment/genrateZoomLink`, payload)
  }

  generateVideoLink(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/payment/genrateVideoLink`, payload)
  }

  createVideoLink(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/telemedicine/createMeeting`, payload)
  }

  getHostMeetingLink(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/payment/getHostMeetingLink`, payload)
  }

  getHostMeetingVideoLink(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/payment/getHostMeetingVideoLink`, payload)
  }

  // getInVCHostMeetingVideoLink(payload) {
  //   return this.http.post(`${environment.patient_registration_prefix_url}/telemedicine/getMeeting`, payload)
  // }

  getInVCHostMeetingVideoLink(payload): Observable<any> {
    // Instead of making an HTTP request, return a dummy observable.
    const dummyData = null;
    return of(dummyData);
  }

  validateMeeting(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/telemedicine/validateMeeting`, payload)
  }
  endMeeting(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/telemedicine/endMeeting`, payload)
  }


  //DropDowns API Form 1
  getFamilyType() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=family_type&form_name=patient_details`);
  }

  getDOB() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=dob&form_name=patient_details`);
  }

  getGender() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=gender&form_name=patient_details`)
  }

  getMaritalStatus() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=marital_status&form_name=patient_details`)
  }

  getEducation() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=education&form_name=patient_details`)
  }

  getReligion() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=religion&form_name=patient_details`)
  }

  getNationality() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=nationality&form_name=patient_details`)
  }

  getOccupation() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=occupation&form_name=patient_details`)
  }

  getSocioEconomicStatus() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=socio_economic_status&form_name=patient_details`)
  }

  //feedback api

  getAttentionConcentrationDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=attention_n_concentration&form_name=feedback_form`)
  }

  getSleepDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=sleep&form_name=feedback_form`)
  }

  getAppetiteDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=appetite&form_name=feedback_form`)
  }

  getMoodAffectDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=mood_n_affect&form_name=feedback_form`)
  }

  getSelfCareGroomingDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=self_care_n_grooming&form_name=feedback_form`)
  }

  getDailyRoutineDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=daily_routine&form_name=feedback_form`)
  }

  getRelationshipDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=relationship_n_social_interaction&form_name=feedback_form`)
  }

  getMedicalComplianceDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=medical_compliance&form_name=feedback_form`)
  }

  addFeedback(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientFeedback`, payload)
  }

  getFeedbackGrid(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getFeedbackGrid`, payload)
  }
  getFeedbackHistory(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getFeedbackHistory`, payload)
  }

  getFeedbackGraphHistory(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getFeedbackStatistics`, payload)
  }

  //Activity api

  getparticipationDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=participation&form_name=activity_form`)
  }

  getunderstandinginstructionsDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=understanding_instructions&form_name=activity_form`)
  }

  getresponsequalityDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=response_quality&form_name=activity_form`)
  }

  getactivityconcentrationDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=activity_concentration&form_name=activity_form`)
  }

  getinteractiveDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=interactive&form_name=activity_form`)
  }

  getcooperationlevelDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=cooperation_level&form_name=activity_form`)
  }

  getfinishingabilityDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=finishing_ability&form_name=activity_form`)
  }


  addActivity(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientActivity`, payload)
  }
  getActivityGrid(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getActivityGrid`, payload)
  }

  getActivityHistory(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getActivityHistory`, payload)
  }

  getActivityGraphHistory(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getActivityStatistics`, payload)
  }
  //Session form - psychiatrist
  getmedicalComplianceDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=medication_compliance&form_name=session_form_psychiatrist`)
  }

  getmedicalResponseDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=medication_response&form_name=session_form_psychiatrist`)
  }

    getSessionGrid(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getSessionGrid`, payload)
  }
  getmedicalToleranceDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=medication_tolerability&form_name=session_form_psychiatrist`)
  }
  addPsychiatristData(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientSession`, payload)
  }

  getSessionHistory(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getSessionHistory`, payload)
  }



  //session form of psychology
  addPsychologistData(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientSession`, payload)
  }

  getCooperationDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=cooperation&form_name=session_form`)
  }
  getMoodDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=mood&form_name=session_form`)
  }
  getSleepPsychoDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=sleep&form_name=session_form`)
  }
  getstabilizationPsychoDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=stabilization&form_name=session_form`)
  }
  getRoutinePsychoDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=routine&form_name=session_form`)
  }
  getinsightDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=insight&form_name=session_form`)
  }
  getAppetitePsychoDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=appetite&form_name=session_form`)
  }

  getcopingskillsDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=coping_skills&form_name=session_form`)
  }
  getRelapsepreventionDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=relapse_prevention&form_name=session_form`)
  }
  getsymptomologyDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=symptomology&form_name=session_form`)
  }
  getMedicalcomplianceDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=medical_compliance&form_name=session_form`)
  }
  getSideeffectDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=side_effect&form_name=session_form`)
  }
  getOverallprogressDetails() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=overall_progress&form_name=session_form`)
  }

  getSessionGraphHistory(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getSessionStatistics`, payload)
  }

  // Form 5 -- Chief Complaints Dropdowns Api

  getduration_of_complaint() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=complaint_duration&form_name=chief_complaint`)
  }

  getBiologicalSleep() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=biological_sleep&form_name=chief_complaints`)
  }
  getBiologicalAppetite() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=biological_appetite&form_name=chief_complaints`)
  }
  getBiologicalBowelMovements() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=biological_bowel&form_name=chief_complaints`)
  }
  getBiologicalBladder() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=biological_bladder&form_name=chief_complaints`)
  }
  getBiologicalWeight() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=biological_weight&form_name=chief_complaints`)
  }
  getBiologicalSexualFunctioning() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=sexual_funtioning&form_name=chief_complaints`)
  }
  getBiologicalSexualEjaculation() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=sexual_ejaculation&form_name=chief_complaints`)
  }
  getBiologicalSexualDesire() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=sexual_desire&form_name=chief_complaints`)
  }


  getCognitiveFunctionMemory() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=memory&form_name=chief_complaints`)
  }

  getOrientationCognitiveFunction() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=orientation&form_name=chief_complaints`)
  }
  getAnxity() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=anxiety_or_ocd&form_name=chief_complaints`)
  }

  getAnxityPresent() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=anxiety_present&form_name=chief_complaints`)
  }

  getRecurrentPersistent() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=thought&form_name=chief_complaints`)
  }

  getRecurrentPersistentPresent() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=thought_present&form_name=chief_complaints`)
  }
  getMultipleBodilySymptoms() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=multiple_bodily_symptoms&form_name=chief_complaints`)
  }
  getDissociation() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=dissociation&form_name=chief_complaints`)
  }
  getDissociationPresent() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=dissociation_present&form_name=chief_complaints`)
  }
  getAdjustmentProblems() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=adjustment_problems&form_name=chief_complaints`)
  }

  getBehaviouralIssues() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=behavioural_issues&form_name=chief_complaints`)
  }

  getPsychoticSymptoms() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=psychotic_symptoms&form_name=chief_complaints`)
  }
  getNegativeSymptoms() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=negative_symptoms&form_name=chief_complaints`)
  }
  getNegativeSymptomsPresent() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=negative_symptoms_present&form_name=chief_complaints`)
  }
  getMoodSymptoms() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=mood_symptoms&form_name=chief_complaints`)
  }

  getSuicidalThoughts() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=suicidal_thoughts&form_name=chief_complaints`)
  }

  getSuicidalThoughtsPresent() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=suicidal_thoughts_present&form_name=chief_complaints`)
  }

  getSubstance() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=substance&form_name=chief_complaints`)
  }


  getChildAdolescent() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=child_and_adolescent&form_name=chief_complaints`)
  }
  getLearningProblems() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=learning_problems&form_name=chief_complaints`)
  }

  getLearningProblemsPresent() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=learning_problems_present&form_name=chief_complaints`)
  }


  getLanguageProblems() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=language_problem&form_name=chief_complaints`)
  }


  getLanguageProblemsPresent() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=language_problem_present&form_name=chief_complaints`)
  }

  getCommunication() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=communication&form_name=chief_complaints`)
  }

  getCommunicationNormal() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=communication_present&form_name=chief_complaints`)
  }

  getActivity() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=activity&form_name=chief_complaints`)
  }

  getActivityNormal() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=activity_present&form_name=chief_complaints`)
  }

  getSpeechProblem() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=speech_problem&form_name=chief_complaints`)
  }

  getSpeechProblemPresent() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=speech_problem_present&form_name=chief_complaints`)
  }

  // getSuicidalThoughts() {
  //   return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=suicidal_thoughts&form_name=chief_complaints`)
  // }

  getDurationOfCompliants() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=complaint_duration&form_name=chief_complaint`)
  }

  getTotalDurationOfIllness() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=total_duration_of_illness&form_name=illness`)
  }

  getOnset() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=onset&form_name=illness`)
  }

  getCourse() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=course&form_name=illness`)
  }


  getPrecipatingFactor() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=precipitating_factor&form_name=illness`)
  }

  getPredisposingFactor() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=predisposing_factors&form_name=illness`)
  }

  getPerpetuatingFactor() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=perpetuating_factors&form_name=illness`)
  }

  getSocioOccupationalFunctioning() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=history_of_present_illness&form_name=history`)
  }

  getMedicalSurgicalHistory() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=medical_or_surgical_history&form_name=history`)
  }
  getMedicalSurgicalHistoryPresent() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=medical_or_surgical_history_present&form_name=history`)
  }
  getFamilyHistoryOfMentalIllness() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=family_history_of_mental_illness_or_drug_addiction&form_name=history`)
  }
  getFamilyHistoryOfMentalIllnessPresent() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=family_history_of_mental_illness_or_drug_addiction_present&form_name=history`)
  }
  getFamilyInterpersonalRelationship() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=family_interpersonal_relationship&form_name=history`)
  }

  getFamilyAttitudeTowardsIllness() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=family_attitude_towards_illness&form_name=history`)
  }

  getBirthHistory() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=birth_history&form_name=history`)
  }

  getDevelopmentalMilestones() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=developmental_milestones&form_name=history`)
  }

  getChildhoodAbuse() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=childhood_abuse_or_trauma&form_name=history`)
  }

  getChildhoodAbusePresent() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=childhood_abuse_or_trauma_present&form_name=history`)
  }

  getSocialInteraction() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=social_interaction&form_name=history`)
  }

  getParentingStyle() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=parenting_style&form_name=history`)
  }

  getTemperament() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=temperament&form_name=history`)
  }

  getHistoryBehaviouralIssues() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=behavioural_issues&form_name=history`)
  }

  getAcademicPerformance() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=education_history&form_name=history`)
  }

  getSocialConduct() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=social_conduct_or_behavioral_issues&form_name=history`)
  }

  getSocialConductPresent() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=social_conduct_or_behavioral_issues_present&form_name=history`)
  }

  getWorkplacePerformance() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=workplace_performance&form_name=history`)
  }

  getWorkplaceRelationship() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=workplace_relationship&form_name=history`)
  }

  getQualityOfRelationship() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=quality_of_relationship&form_name=history`)
  }

  getPreMorbidQualityOfRelationship() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=pre_morbid_personality&form_name=history`)
  }

  getRelationshipMaritalQualityOfRelationship() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=relationship_or_marital_and_sexual_history&form_name=history`)
  }

  getTypeOfSubstance() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=substance_and_legalhistory&form_name=history`)
  }

  getTypeOfSymptom() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=type_of_symptom&form_name=history`)
  }

  getOutcomeOfTreatment() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=outcome_of_treatment&form_name=history`)
  }

  getTreatmentCompliance() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=treatment_compliance&form_name=history`)
  }

  // getamilyHistoryMentalIllness() {
  //   return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=treatment_compliance&form_name=history`)
  // }


  getSubstancesBehavior() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=substances_or_behavior&form_name=chief_complaints`)
  }

  getPattern() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=pattern&form_name=chief_complaints`)
  }

  getAssociatedSymptoms() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=associated_symptoms&form_name=chief_complaints`)
  }

  UpdateCaseHistoryDetails(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientHistory`, payload)
  }

  //Drop Down APIs Form 3 

  getModeOfEntry() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=mode_of_entry&form_name=mental_state_examination`);
  }

  getLevelConsciousness() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=level_of_consciousness&form_name=mental_state_examination`);
  }
  getAppearanceList() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=appearance&form_name=mental_state_examination`);
  }
  getGrooming() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=grooming&form_name=mental_state_examination`);
  }
  getAbnormalMovements() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=abnormal_movements&form_name=mental_state_examination`);
  } AttitudeTowardsExaminer

  getAttitudeTowardsExaminer() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=attitude_towards_examiner&form_name=mental_state_examination`);
  }
  getOrientation() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=orientation&form_name=mental_state_examination`);
  }

  getDisorientation() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=orientation_present&form_name=mental_state_examination`);
  }
  getEyeToEyeContact() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=eye_to_eye_contact&form_name=mental_state_examination`);
  }
  getAttention() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=attention&form_name=mental_state_examination`);
  }
  getRapport() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=rapport&form_name=mental_state_examination`);
  }
  getPsychomotorActivity() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=psychomotor_activity&form_name=mental_state_examination`);
  }
  getAppropriateness() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=appropriateness&form_name=mental_state_examination`);
  }
  getCatatonicFeatures() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=catatonic_features&form_name=mental_state_examination`);
  }
  getSpeechQuality() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=speech&form_name=mental_state_examination`);
  }
  getSpeechRate() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=speech_rate&form_name=mental_state_examination`);
  }
  getSpeechTone() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=speech_tone&form_name=mental_state_examination`);
  }
  getSpeechVolume() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=speech_volume&form_name=mental_state_examination`);
  }
  getSpeechRelevance() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=speech_relevance&form_name=mental_state_examination`);
  }
  getSpeechCoherance() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=speech_coherance&form_name=mental_state_examination`);
  }
  getMood() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=mood&form_name=mental_state_examination`);
  }
  getPredominantAffect() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=affect&form_name=mental_state_examination`);
  }
  getRange() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=range&form_name=mental_state_examination`);
  }
  getIntensity() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=intensity&form_name=mental_state_examination`);
  }
  getCongruence() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=congruence&form_name=mental_state_examination`);
  }
  getThought() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=thought_form&form_name=mental_state_examination`);
  }
  getThoughtFormPresent() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=thought&form_name=mental_state_examination`);
  }
  getStream() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=stream&form_name=mental_state_examination`);
  }
  getStreamPresent() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=stream_problems&form_name=mental_state_examination`);
  }
  getPossession() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=possession&form_name=mental_state_examination`);
  }
  getPossessionPresent() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=possession_present&form_name=mental_state_examination`);
  }
  getContent() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=content&form_name=mental_state_examination`);
  }
  getDelusions() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=delusions_present&form_name=mental_state_examination`);
  }
  getObsessionCompulsion() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=obsession_compulsion&form_name=mental_state_examination`);
  }
  getObsessionCompulsionSpecify() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=obsession_compulsion_present&form_name=mental_state_examination`);
  }
  getPerceptionType() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=perception&form_name=mental_state_examination`);
  }
  getModality() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=modality&form_name=mental_state_examination`);
  }
  getIntelligence() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=intelligence&form_name=mental_state_examination`);
  }
  getInsight() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=insight&form_name=mental_state_examination`);
  }
  getMotivation() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=thought_present&form_name=mental_state_examination`);
  }
  getMemory() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=memory&form_name=mental_state_examination`);
  }
  getMemoryImpairedRadio() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=memory_impaired&form_name=mental_state_examination`);
  }
  getMemoryIntact() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=memory_multiple&form_name=mental_state_examination`);
  }
  getMemoryImpaired() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=memory_multiple&form_name=mental_state_examination`);
  }
  getJudgement() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=judgement&form_name=mental_state_examination`);
  }
  getJudgementIntact() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=judgement_multiple&form_name=mental_state_examination`);
  }
  getJudgementImpaired() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=judgement_multiple&form_name=mental_state_examination`);
  }


  UpdateProvisionalDiagnosis(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientProvisionDiagnosisTreatment`, payload)
  }

  UpdatePatientMSEAppearanceBehaviour(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientMSEAppearanceBehaviour`, payload)
  }


  provisionDiagnosisChcekbox(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/matrix/getDiagnosisBySymptoms`, payload)
  }

  // getAiResponse(payload) {
  //   return this.http.post(`${environment.patient_registration_prefix_url}/ai/aiResponse`, payload)
  // }

  
  getAiResponse(payload) {
    return this.http.post<AiResponse>(`${environment.patient_registration_prefix_url}/ai/aiResponse`, payload).pipe(
        map(response => {
            const propertiesToCheck = ['provisional_diagnosis_response', 'treatment_response', 'treatment_plan_response', 'assessment_response', 'express_hopi'];
            
            for (const property of propertiesToCheck) {
                if (response && response[property]) {
                    response[property] = response[property].replace(/\n/g, '<br>');
                }
            }

            return response;
        })
    );
}


  //notification 
  getNotificationsCount(payload) {
    return this.http.get(`${environment.patient_registration_prefix_url}/patient/unreadNotificationsCount`, payload);
  }

  getAllNotifications(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getAllNotificationsGrid`, payload);
  }


  setNotificationsRead(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/setNotificationsRead`, payload);
  }


  // Form 4--  Provision Daignosis and MAnagement Dropdowns Api

  getTarget() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=target&form_name=provisional_diagnosis_and_management`);
  }

  getAssessment() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=Assessment&form_name=provisional_diagnosis_and_management`);
  }

  getRelationshipwithPatient() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=relationship_with_patient_type&form_name=patient_details`);
  }



  // Form 9 --> Early Warning

  getEwsFormDetails(data?) {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getEwsFormDetails?field_name=${data}&form_name=early_warning_signs`);
  }

  getPhyschologicalBiological() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=physchological_biological&form_name=early_warning_signs`);
  }

  getSocial() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=social&form_name=early_warning_signs`);
  }

  getpsychological() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=psychological&form_name=early_warning_signs`);
  }

  getOccupationalVocationa() {
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=occupational_vocational&form_name=early_warning_signs`);
  }

  addEarlyWarning(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updateEarlyWarningSigns`, payload)
  }

  getEarlyWarningSigns(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getEarlyWarningSigns`, payload)
  }

  //Revival Lsit

  getRevivalGridDetails(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/getInactiveList`, payload)
  }

  updateRevivalData(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/updateRevivalData`, payload)
  }

  getRevivalPatientData(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/getRevivalPatientData`, payload)
  }

  getPatientRemarks(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/getPatientRemarks`, payload)
  }


  addParticipantProgress(payload){
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/addParticipantsInVc`,payload)
  }

  updateProgressReport(payload){
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updateProgressReport`,payload)
  }

  getProgressReport(payload){
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getProgressReport`,payload)
  }

  getFollowUpListing(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getpatientFollowUpGrid`,payload)
  }

  updateFollowUpDate(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updatePatientFollowUp`,payload)
  }

  getDownloadProgressReport(payload){
    const requestOptions: Object = {
      responseType: 'blob' as 'blob'
    };
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/downloadPatientProgressSheet`,payload, requestOptions)
  }

  getZoomAccessToken() {
    return this.http.get(`${environment.patient_registration_prefix_url}/zoom/zoomauthServer`)
  }

  getZoomMeetingUrls(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/zoom/createMeeting`,payload)
  }

  startZoomMeeting(hostLink) {
    return this.http.get(hostLink); 
  }

  endZoomMeeting(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/zoom/endMeeting`,payload)
  }

  getZoomMeetingDetails(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/zoom/getMeeting`,payload)
  }

  getAudioCall(){
    return this.http.get(`https://auth.tcn.com/login?state=hKFo2SBnVW9qeDVSOEVIeTNFZmk3bFVLNTJkTElFQkVCTUhNWqFupWxvZ2luo3RpZNkgblp3QVZSbVFSNXJBdHZuM2FPaWNNZ09qS2p6NHhrczKjY2lk2SBMaWU0NG9IZ3FSdVdNVzZjU3d5ZlQ2QTJFSXhwcThONQ&client=Lie44oHgqRuWMW6cSwyfT6A2EIxpq8N5&protocol=oauth2&prompt=login&response_type=id_token&redirect_uri=https%3A%2F%2Foperator.tcn.com%2F%3Floginref%3D&scope=openid%20email&nonce=t2tYfzYodXvNy485AgxPDg5xDyV8dr2b&auth0Client=eyJuYW1lIjoiYXV0aDAuanMiLCJ2ZXJzaW9uIjoiOS4xMS4zIn0%3Ds`,)

  }

  getUserDetailsForAudioCall(){
    return this.http.get(`${environment.patient_registration_prefix_url}/ai/getAudioUserDetails`,)

  }

  updatePatientAudioMeeting(payload){
    return this.http.post(`${environment.patient_registration_prefix_url}/ai/updatePatientAudioMeeting`,payload)

  }

  connectWearable(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/terra/connectWidget`, payload)
  }

  terraWerableModels() {
    return this.http.get(`${environment.patient_registration_prefix_url}/terra/wearableProvidersModelsList`)
  }

  saveWearableComments(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/wearable-remarks`, payload)
  }

  updateWearableComment(payload) {
    return this.http.put(`${environment.patient_registration_prefix_url}/patient/wearable-remarks/${payload.remarkId}`, payload)
  }

  deleteWearableComment(payload) {
    return this.http.delete(`${environment.patient_registration_prefix_url}/patient/wearable-remarks/${payload.remarkId}`)
  }

  getAllPatientWearableComments(payload) {
    if(payload.remarkId) {
      return this.http.get(`${environment.patient_registration_prefix_url}/patient/wearable-remarks/${payload.patientId}/${payload.remarkId}`)
    } else {
      return this.http.get(`${environment.patient_registration_prefix_url}/patient/wearable-remarks/${payload.patientId}`)
    }
    
  }

  getAllAssessments() {
    return this.http.get(`${environment.patient_assessment_prefix_url}getAllCatalogeOfAssessment`)
  }

  initiateAssessment(payload) {
    return this.http.post(`${environment.patient_assessment_prefix_url}/sendAssessmentLink`, payload)
  }

  cancelOPD(data, appointmentId) {
    return this.http.put(`${environment.patient_registration_prefix_url}/patient/cancel-opd/${appointmentId}`, data)
  }

  updateOpdRemarks(patientAppointmentId, payload) {
    return this.http.put(`${environment.patient_registration_prefix_url}/patient/patient-remarks/${patientAppointmentId}`, payload)
  }
}
