<ng-template #modalTemplate>
  <div class="popup--profile bg-white">
    <div class="modal-header pt-2 pb-2">
      <h4 class="modal-title">
        Profile
        <!-- <button type="" class="btn icon-close btn-primary popup__close px-0 float-right position-relative" (click)="closePopup()"><span
                      class="icon-close1 text-white"></span></button> -->
      </h4>
      <button (click)="closePopup()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <div class="profile-block">
        <app-profile-form (closeModal)="closePopup()" *ngIf="userDetails"></app-profile-form>
        <app-change-mobilenumber (closeModal)="closePopup()"></app-change-mobilenumber>
        <app-signature></app-signature>
        
        <app-change-password></app-change-password>
        
       
      </div>
    </div>
    <div>
      <app-hierarchy></app-hierarchy>
    </div>
  </div>

</ng-template>