import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RppRenewListService {

  constructor(
    private http: HttpClient
  ) { }

  getPatientRPPRenewGridDetails(payload){
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/getPatientRppRenewGrid`,payload)
  }


}
