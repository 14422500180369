import { UserLevel } from './user-level.model';
import { DataService } from 'src/app/modules/shared/services/data.service';
import { LocationsService } from '../../services/locations.service';
import { FormGroup } from '@angular/forms';
import { Component, Input, OnInit, AfterViewInit, Output, EventEmitter, AfterContentChecked } from '@angular/core';

@Component({
  selector: 'app-common-location-fields',
  templateUrl: './common-location-fields.component.html',
  styleUrls: ['./common-location-fields.component.scss']
})
export class CommonLocationFieldsComponent implements OnInit, AfterContentChecked {
  @Input() parentForm: FormGroup;
  @Input() formSubmitted: boolean;
  @Input() stateDisable = false;
  @Input() districtDisable = false;
  @Input() blockDisable = false;
  @Input() villageDisable = true;
  @Input() stateRequired: boolean;
  @Input() districtRequired: boolean;
  @Input() blockRequired: boolean;
  @Input() stateId: any = null;
  @Input() districtId: any = null;
  @Input() blockId: any = null;
  @Input() formType: any = null;
  @Output() blockSelected = new EventEmitter();
  @Output() districtSelected = new EventEmitter();
  @Output() stateSelected = new EventEmitter();
  @Output() makeBlank = new EventEmitter();
  @Input() stateShow: boolean = true;
  @Input() districtShow: boolean = true;
  @Input() blockShow: boolean = true;
  @Input() disabledValue;
  // @Input() cityVillageId;
  stateList;
  districtList;
  blockList;
  cityVillageList;
  userLevel;
  userStateId;
  userDistrictId;
  userBlockId;
  columnWidth = 4;

  constructor(private locationsService: LocationsService,
              private dataService: DataService) {
    this.userLevel = this.dataService.userDetails.user_level;
    this.userStateId = this.dataService.userDetails.state_id;
    this.userDistrictId = this.dataService.userDetails.district_id;
    this.userBlockId  = this.dataService.userDetails.block_id;
    this.getStateList();
  }

  ngAfterContentChecked(): void {
    if (this.formType === 'add') {
      if ((this.userLevel === UserLevel.STATE) ||
      (this.userLevel === UserLevel.DISTRICT) ||
      (this.userLevel === UserLevel.BLOCK) ||
      (this.userLevel === UserLevel.SITE)) {
        if (this.userStateId) {
          this.parentForm.get('stateId').disable();
          this.parentForm.get('stateId').setValue(this.userStateId);
        }

        if (this.userDistrictId) {
          this.parentForm.get('districtId').disable();
          this.parentForm.get('districtId').setValue(this.userDistrictId);
        }

        if (this.userBlockId) {
          this.parentForm.get('blockId').disable();
          this.parentForm.get('blockId').setValue(this.userBlockId);
        }
      }
    }
    if (!this.stateShow && !this.districtShow) {
      this.columnWidth = 12;
    }

    if (!this.stateShow && this.districtShow) {
      this.columnWidth = 6;
    }

    if (this.stateShow && this.districtShow) {
      this.columnWidth = 4;
    }
    if(this.disabledValue==true){
      this.parentForm.get('stateId').disable();
     this.parentForm.get('stateId').setValue(this.stateId);
    }
  }

  ngOnInit(): void {
    this.setDataInLocation();
    this.enableDisable();
  }

  enableDisable() {
    if (this.blockDisable) {
      this.parentForm.get('blockId').disable();
    } else {
      this.parentForm.get('blockId').enable();
    }

    if (this.districtDisable) {
      this.parentForm.get('districtId').disable();
    } else {
      this.parentForm.get('districtId').enable();
    }

    if (this.stateDisable) {
      this.parentForm.get('stateId').disable();
    } else {
      this.parentForm.get('stateId').enable();
    }
  }

  getStateList() {
    if ((this.userLevel === UserLevel.STATE) ||
        (this.userLevel === UserLevel.DISTRICT) ||
        (this.userLevel === UserLevel.BLOCK) ||
        (this.userLevel === UserLevel.SITE)) {
          this.getDistrictList(this.userStateId);
    }
    this.makeBlank.emit(['districtId', 'blockId']);
    this.locationsService.getStates().subscribe((res) => this.stateList = res);
    console.log("state list is", this.stateList)
  }

  getDistrictList(stateId) {
    if ((this.userLevel === UserLevel.DISTRICT) ||
        (this.userLevel === UserLevel.BLOCK) ||
        (this.userLevel === UserLevel.SITE)) {
          this.getBlockList(this.userDistrictId);
    }
    this.stateSelected.emit(stateId);
    this.makeBlank.emit(['blockId']);
    this.locationsService.getDistricts(stateId).subscribe((res) => this.districtList = res);
    console.log("district is", this.districtList);
    


  }

  getBlockList(districtId) {
    this.districtSelected.emit(districtId);
      this.locationsService.getBlocks(districtId).subscribe((res) => this.blockList = res);
  }

  getCityVillageList(blockId) {
    this.blockSelected.emit(blockId);
    // this.locationsService.getVillages(blockId).subscribe((res) => this.cityVillageList = res);
  }

  setDataInLocation() {
    console.log(this.stateRequired + '@this.stateId' + this.stateId, 'this.districtId' + this.districtId);
    if (this.stateId) {
      this.getDistrictList(this.stateId);
    }

    if (this.districtId) {
      this.getBlockList(this.districtId);
    }
  }

}
