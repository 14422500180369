import { DataService } from 'src/app/modules/shared/services';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../screens/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router, public dataService: DataService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    // Allow access to routes starting with 'feedback' without authentication
    if (state.url.startsWith('/feedback')) {
      return true;
    }

    if (state.url.startsWith('/consent')) {
      return true;
    }
    
    if (this.auth.currentUserValue) {
      if (state.url === "/login") {
        this.auth.redirectAsperPermission();
      }
      return true;
    } else {
      if (state.url !== "/login") {
        this.router.navigate(['/login']);
      }
      return true;
    }
  }

}
