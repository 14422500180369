<div class="form" [formGroup]="parentForm">
    <div class="row">
        <div class="col-lg-4 col-md-{{columnWidth}} col-sm-12">
            <div class="form-group" *ngIf="stateShow">
                <label for="" class="form-label">State/UT{{stateRequired ? '*' : '' }}</label>
                <p-dropdown styleClass="" [options]="stateList" formControlName='stateId'
                [ngClass]="{'invalid-field': formSubmitted && parentForm.get(['stateId']).hasError('required')}"
                placeholder="Please select State"
                (onChange)="getDistrictList(parentForm.get(['stateId']).value)"
                optionLabel="state_name"
                optionValue='state_id'>
                </p-dropdown>
            </div>
        </div>
      <div class="col-lg-4 col-md-{{columnWidth}} col-sm-12">
        <div class="form-group" *ngIf="districtShow">
            <label for="" class="form-label">District{{districtRequired ? '*' : '' }}</label>
            <p-dropdown styleClass="" [options]="districtList" formControlName='districtId'
            [ngClass]="{'invalid-field': formSubmitted && parentForm.get(['districtId']).hasError('required')}"
            placeholder="Please District State"
            (onChange)="getBlockList(parentForm.get(['districtId']).value)"
                optionLabel="district_name" optionValue='district_id'>
            </p-dropdown>
        </div>
      </div>
    <div class="col-lg-4 col-md-{{columnWidth}} col-sm-12">
        <div class="form-group" *ngIf="blockShow">
            <label for="" class="form-label">City{{blockRequired ? '*' : '' }}</label>
            <p-dropdown styleClass="" [options]="blockList" formControlName='blockId'

                optionLabel="city_name" optionValue='city_id'>
            </p-dropdown>
        </div>
    </div>

    </div>
</div>
