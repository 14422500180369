import { EncDecService } from './../../../services/encryption-decryption.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/screens/auth/services/auth.service';
import { ProfileService, DataService, UtilsService } from '../../../services';

@Component({
  selector: 'app-change-mobilenumber',
  templateUrl: './change-mobilenumber.component.html',
  styleUrls: ['./change-mobilenumber.component.scss']
})
export class ChangeMobilenumberComponent implements OnInit {

  @Output() closeModal = new EventEmitter();
  formSubmitted = false;
  changeMobileNoSubmitted = false;
  userDetails;
  otpSent = false;
  resentOtp = false;
  resendTimer = 0;
  changeMobileForm: FormGroup;

  constructor(private profileService: ProfileService,
              public dataService: DataService,
              private util: UtilsService,
              private auth: AuthService,
              private encDecService: EncDecService) { }

  ngOnInit(): void {
    this.initForm();
    this.userDetails = this.dataService.userDetails;
    this.changeMobileForm.controls.mobileNumber.setValue(this.userDetails?.mobile_number);
    this.changeMobileForm.controls.mobileNumber.disable();
    this.changeMobileForm.controls.otp.disable();
  }

  initForm(): void {
    this.changeMobileForm = new FormGroup({
      mobileNumber: new FormControl('', [Validators.required, Validators.minLength(10)]),
      otp: new FormControl('', [Validators.required]),
      newMobileNumber: new FormControl('', [Validators.required, Validators.minLength(10)]),
    });
  }

  // convenience getter for easy access to form fields
  get formControls(): any {
    return this.changeMobileForm.controls;
  }


  onSubmit(): void {
    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.changeMobileForm.invalid) {
        return;
    }
    if (this.otpSent) {
      this.updateMobile();
    } else {
      this.sendOtp();
    }
  }

  sendOtp(): void {
    if (this.resendTimer > 0) { return; }
    this.resentOtp = false;
    this.profileService.sendOTP({
      mobileNumber: this.changeMobileForm.controls.mobileNumber.value,
      newMobileNumber: this.changeMobileForm.controls.newMobileNumber.value
    }).subscribe(
      () => {
        this.resendTimer = 120;
        this.startTimer();
        setTimeout(() => {
          this.resentOtp = true;
        }, 120000);
        this.otpSent = true;
        this.changeMobileForm.controls.otp.markAsUntouched();
        this.formSubmitted = false;
        this.changeMobileForm.controls.newMobileNumber.disable();
        this.changeMobileForm.controls.otp.enable();
        const message = 'Enter the OTP(One Time Password) sent to +91 ';
        this.util.showSuccessToast(`${message}${this.changeMobileForm.get('mobileNumber').value}`);
      },
      error => {
        if (error?.error?.errorCode == 'USRPRF00030') {
          this.resendTimer = 120;
          this.startTimer();
          setTimeout(() => {
            this.resentOtp = true;
          }, 120000);
          this.otpSent = true;
          this.changeMobileForm.controls.otp.markAsUntouched();
          this.formSubmitted = false;
          this.changeMobileForm.controls.newMobileNumber.disable();
          this.changeMobileForm.controls.otp.enable();
        }
      }
    );
  }

  startTimer(): void {
    if (this.resendTimer){
      setTimeout(() => { this.resendTimer--; this.startTimer(); }, 1000);
    }
  }

  updateMobile(): void {
    this.profileService.updateMobileNo({
      mobileNumber: this.changeMobileForm.controls.mobileNumber.value,
      newMobileNumber: this.changeMobileForm.controls.newMobileNumber.value,
      otp: this.encDecService.set(''+this.changeMobileForm.controls.otp.value)
    }).subscribe(
      r => {
        this.formSubmitted = false;
        this.otpSent = false;
        this.changeMobileForm.controls.newMobileNumber.enable();
        this.changeMobileForm.controls.newMobileNumber.markAsUntouched();
        this.util.showSuccessToast('Mobile number has been updated successfully. Kindly login with new mobile number with the existing password');
        this.changeMobileForm.reset();
        this.changeMobileForm.controls.otp.markAsUntouched();
        this.changeMobileForm.markAsUntouched();
        setTimeout(() => {
          this.closeModal.emit();
          this.auth.logout();
        }, 5000);
      },
      error => {
      }
    );
  }

}
