import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {
  @Input() contentId: string;
  content;
  currentLang: string;

  constructor(private sanitizer: DomSanitizer,
              private configService: ConfigService) { }

  ngOnInit(): void {
    const key = this.contentId + '_en';
    this.configService.getConfig(key).subscribe((res: any) => {
      if (res) {
          this.content = this.sanitizer.bypassSecurityTrustHtml(res.value);
      }
    },
    er => {
      this.content = '';
    });
  }

}
