import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { CdkPortalOutlet, PortalOutlet, TemplatePortal } from '@angular/cdk/portal';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { GenericPopupService } from './services/generic-popup.service';
import { ModalPosition, ModalState } from './model';

@Component({
  selector: 'app-generic-popup',
  templateUrl: './generic-popup.component.html',
  styleUrls: ['./generic-popup.component.scss']
})
export class GenericPopupComponent implements OnInit {

  @ViewChild('modal', { static: false }) modal: ModalDirective;
  @ViewChild(CdkPortalOutlet, { static: false }) portalOutlet: PortalOutlet;

  modalPositionEnum = ModalPosition;

  public position: ModalPosition;

  constructor(private genericPopupService: GenericPopupService) {
  }

  ngOnInit() {
    this.genericPopupService.state
      .subscribe((state: ModalState) => {
        if (!!state) {
          if (state.open) {
            this.modal.show();
          } else {
            this.modal.hide();
          }
          this.position = state.position || this.position;
        }
      });

    this.genericPopupService.portal
      .subscribe((portal: TemplatePortal) => {
        if (!!portal) {
          this.portalOutlet.detach();
          this.portalOutlet.attach(portal);
        }
      });
  }

  @HostListener('mousedown', ['$event'])
  onClick(event: any) {
    if (event.target.classList.contains('modal')) {
      this.genericPopupService.close();
    }
  }

  @HostListener('keydown', ['$event'])
  onEsc(event: any) {
    if (event.key === 'Escape') {
      this.genericPopupService.close();
    }
  }

}
