<p-toast class="custom-toast"></p-toast>
<footer class="main-footer bg-primary">
  <div class="pull-right d-none d-sm-inline-block">
      <ul class="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
    
    <li class="nav-item">
    <span class="text-small py-2">version {{app_version}}</span>
    </li>
  </ul>
  </div>

  <div *ngIf="isKeyInSessionStorage('userToken') && isTechSupport" class="support-button-float" (click)="showDialog()">
      <i class="ti ti-headphone"> 
      </i>
  </div>

  <p-dialog #fileUploadRef header="Enter your query" [(visible)]="visible" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
    <form [formGroup]="supportForm" (submit)="submitSupportForm()" >
      <textarea pInputTextarea formControlName="query"></textarea>
      <div class="invalid-feedback" *ngIf="supportForm.get(['query']).hasError('required') && submitted"
        tooltip="Mandatory" placement="bottom">
        Mandatory
      </div>
      <p-fileUpload #fileUpload name="myfile[]" (onSelect)="onSelectFile($event)" [auto]="true" [multiple]="true" accept="image/*" maxFileSize="1000000">
      </p-fileUpload>
      <button class="btn btn-primary me-3 btn-md" type="submit">Submit</button>
      <button class="btn btn-danger btn-md" type="button" (click)="clearImageUpload()">Cancel</button>
    </form>
</p-dialog>
  
  © <script>document.write(new Date().getFullYear())</script>2023 <a href="https://www.emoneeds.com/">emoneeds</a>. All Rights Reserved.
</footer>