import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/screens/auth/services/auth.service';
import { DataService, ProfileService, UtilsService } from '../../../services';
import { EncDecService } from '../../../services/encryption-decryption.service';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit {
  signatures = {
    '/9j/4AAQS': 'image/jpg',
    '/9j/4Qxi': 'image/jpg',
    iVBORw0KGgo: 'image/png'
  };
  
  uploadedFileNameValidation = /^[ A-Za-z0-9_\-]*$/;
  constructor(private profileService: ProfileService,
    public dataService: DataService,
    private util: UtilsService,
    private cdr: ChangeDetectorRef,
    private auth: AuthService,
    private encDecService: EncDecService) { }

  ngOnInit(): void {
  }
  detectMimeType(b64) {
    for (const s in this.signatures) {
      if (b64.indexOf(s) > 0) {
        return this.signatures[s];
      }
    }
  }
  onSelectFile(event): void {
    
    if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (e: any) => {
          const mimeType = this.detectMimeType(reader.result);
          if (mimeType) {
            const name = event.target.files[0].name;
            const fileNameWithOutExtension = name.substring(0, name.lastIndexOf('.'));
            if (this.uploadedFileNameValidation.test(fileNameWithOutExtension)) {
              const formData = new FormData();
              formData.append('file',  event.target.files[0]);
              this.profileService.savesignaturePic(formData)
              .subscribe(response => {
                const reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]); // read file as data url
                reader.onload = (e) => { // called once readAsDataURL is completed
                  this.dataService.setSignaturePic(e.target.result);
                  this.util.showSuccessToast('Signature Picture Uploaded Successfully');
                };
              }, error => {
              if (error.status === 400){
                // this.util.showErrorToast(this.translate.instant('Format Not Supported'));
              } else {
                this.util.showErrorToast('Error In Signature Upload');
              }});
            } else {
              event.target.files = null;
              this.util.showErrorToast(`Invalid file name`);
            }
          } else {
            event.target.files = null;
            this.util.showErrorToast('Invalid file type, allowed extensions are PNG,JPEG,JPG');
          }
        };
    }
  }

  
}
