import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';

import {ModalPosition, ModalState, ModalType} from '../model';

@Injectable({
  providedIn: 'root'
})
export class GenericPopupService {

  private state$ = new BehaviorSubject<ModalState>(undefined);
  private portal$ = new BehaviorSubject<TemplatePortal>(undefined);
  private type$ = new BehaviorSubject<any>(undefined);

  get state(): Observable<ModalState> {
    return this.state$.asObservable();
  }

  get portal(): Observable<TemplatePortal> {
    return this.portal$.asObservable();
  }

  get type(): Observable<any> {
    return this.type$.asObservable();
  }

  open(position: ModalPosition, type?: ModalType, params?: {}) {
    this.type$.next({type, params});
    this.state$.next({open: true, position});
  }

  close() {
    this.state$.next({open: false, position: undefined});
  }

  setModalPortal(portal: TemplatePortal) {
    this.portal$.next(portal);
  }
}
