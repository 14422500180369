import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Colmodel, GridFIlterType } from 'src/app/modules/commongrid/colmodel.model';
import { BasicRegistrationService } from '../hospital-basic-registration/services/basic-registration.service';

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss']
})
export class SuggestionsComponent implements OnInit {
  ai_suggestion: FormGroup
  constructor(private basicRegService: BasicRegistrationService) { }

  ngOnInit(): void {
    this.initForm()
  }


  initForm() {
    this.ai_suggestion = new FormGroup({
      search: new FormControl('',),
      search_result: new FormControl('', ),
    })

}

submit(){
  let formdata = this.ai_suggestion.getRawValue();
  console.log('formdata', formdata);
  let payload = {
    "message":formdata.search
  }
  this.basicRegService.getAiResult(payload).subscribe(res =>{
this.ai_suggestion.patchValue({
  search_result: res['reply']
})
  })
  
}
}
