import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  // getUserDetails() {
  //   return this.http.post<any>(environment.user_prefix_url+'/getDetails', {});
  // }

  downloadUserTemplate(fileName) {
    let requestOptions: Object = {
      responseType: 'blob' as 'blob'
    }
    return this.http.get<any>(`${environment.admin_prefix_direct_url}/import-export/download/template/${fileName}`, requestOptions);
  }

  downloadRolesTemplate(fileName){
    let resquestOptions: Object = {
      responseType: 'blob' as 'blob'
    }
    return this.http.get<any>(`${environment.admin_prefix_url}/`)
  }

  getAllUserImportTemplate(){
    return this.http.get<any>(`${environment.admin_prefix_url}/users/getUserImportTemplate`)
  }

  startImport(url, postData) {
    return this.http.post<any>(`${url}`, postData)
    .pipe(timeout(20 * 60 * 1000));
  }

  downloadFile(fileUrl) {
    const requestOptions: Object = {
      responseType: 'blob' as 'blob'
    };
    return this.http.get<any>(`${environment.admin_prefix_url_port}${fileUrl}`, requestOptions);
  }

  getAllCCPByBlockId(blockId) {
    return this.http.get<any>(`${environment.admin_prefix_url}/ccp/getAllCCPByBlockId/${blockId}`)
  }

  getAllCCPByDistrictId(districtId) {
    return this.http.get<any>(`${environment.admin_prefix_url}/ccp/getAllCCPByDistrictId/${districtId}`)
  }

  getCCPByDistrictId(districtId) {
    return this.http.get<any>(`${environment.admin_prefix_url}/ccp/getCCPByDistrictId/${districtId}`)
  }

  getAllCCPByBlockIdExcludingOthers(blockId) {
    return this.http.get<any>(`${environment.admin_prefix_url}/ccp/getAllCCPByBlockIdExcludingOthers/${blockId}`)
  }

  getAllCCPByDistrictIdExcludingOthers(districtId) {
    return this.http.get<any>(`${environment.admin_prefix_url}/ccp/getAllCCPByDistrictIdExcludingOthers/${districtId}`)
  }

  getAllCCPByDistrict() {
    return this.http.get<any>(`${environment.admin_prefix_url}/ccp/getAllCCPByDistrict`)
  }

  getMaterial(isExport) {
    const requestOptions: Object = {
      responseType: 'blob' as 'blob'
    };
    if (!isExport) {
      return this.http.get<any>(`${environment.admin_prefix_url}/material/getMaterial/${isExport}`);
    } else {
      return this.http.get<any>(
        `${environment.admin_prefix_url}/material/getMaterial/${isExport}`,
        requestOptions
      );
    }
  }

  getFrontLineMinistryList() {
    return this.http.get<any>(`${environment.admin_prefix_url}/role/getMinistryList`)
  }

  getCentralMinistryList() {
    return this.http.get<any>(`${environment.admin_prefix_url}/role/getCentralMinistryList`)
  }

  getStates() {
    return this.http.get(`${environment.admin_prefix_url}/location/states`)
  }

  getDistricts(stateID: number) {
    return this.http.get(`${environment.admin_prefix_url}/location/districts/${stateID}`);
  }

  getBlocks(distID: number) {
    return this.http.get(`${environment.admin_prefix_url}/location/blocks/${distID}`)
  }

}
