import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import io from 'socket.io-client';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class WebSocketService {

    private socketLiveCounter;
    constructor() {
      // this.liveCounterSocketConnect();
    }

    liveCounterSocketConnect() {
        this.socketLiveCounter = io(`${environment.websocket_prefix_direct_url}`, {transports: ['websocket']}).connect();
    }

    listen(eventName: string) {
      return new Observable((subscriber) => {
        this.socketLiveCounter.on(eventName, (data: any) => {
          subscriber.next(data);
        })
      })
    }


    emit(eventName: string, data: any) {
      this.socketLiveCounter.emit(eventName, data);
    }


    disconnect() {
      if (this.socketLiveCounter) {
          try {
              this.socketLiveCounter.disconnect();
          } catch (error) {
              console.log(error);
          }
      }
    }
}
