<header class="main-header" *ngIf="(auth.currentUser | async)">
	<app-profile *ngIf="(auth.currentUser | async)"></app-profile>
	  <!-- <app-hierarchy *ngIf="(auth.currentUser | async)"></app-hierarchy>  -->
	<div class="d-flex align-items-center logo-box justify-content-start">
		<!-- Logo -->
		<a  class="logo">
			<!-- logo-->
			<div class="logo-mini w-50">
				<span class="light-logo" ><img src="assets/images/logo.svg" alt="logo"></span>

			</div>
			<div class="logo-lg">

				<span class="light-logo" ><img src="assets/images/logo-text.svg" alt="logo"></span>
			</div>
		</a>
	</div>
	<!-- Header Navbar -->
	<nav class="navbar navbar-static-top">
		<!-- Sidebar toggle button-->
		<div class="app-menu">
			<ul class="header-megamenu nav">
				<li class="btn-group nav-item">
					<a href="#" class="waves-effect waves-light nav-link push-btn btn-primary-light"
						data-toggle="push-menu" role="button">
						<!-- <i data-feather="align-left"></i> -->
            <i-feather name="align-left"></i-feather>
					</a>
				</li>
			</ul>
			
		</div>
		<!-- <div class="text-white">Logout at {{ tokenExpireyTime | date:'d MMM yyyy, h:mm a' }}</div> -->
		<div class="navbar-custom-menu r-side">
			
			<ul class="nav navbar-nav">
				
				<li class="dropdown notifications-menu">
					
					<a href="#" class="waves-effect waves-light dropdown-toggle btn-info-light" data-bs-toggle="dropdown" title="Notification" (click)="editCount()">
						<i class="pi pi-bell p-mr-4 text-white" pBadge style="font-size:1.5rem" [value]="NotificationCount?.count"></i>
					</a>
					<ul class="dropdown-menu animated bounceIn" style="background-color: #ffff !important;">
		
					  <li class="header">
						<div class="p-10">
							<div class="flexbox">
								<div>
									<h5 class="mb-0 mt-0">Notifications</h5>
								</div>
							</div>
						</div>
					  </li>
		
					  <li>
						<!-- inner menu: contains the actual data -->
						<div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto; height: 180px;">
							<ul class="menu sm-scrol" style="overflow: hidden; width: auto; height: 180px;" >
						  
						  <li *ngFor="let allNotification of allNotification; let i = index">
							<a>
							 <img class="rx" src="assets/images/rx.png"> {{allNotification.notification_description}} <span class="notification_span fs-10 mb-0 text-capitilize text-mute pull-right">{{allNotification.age}}</span>
							</a>
						  </li>
						  <li *ngIf="allNotification?.length == 0">
							<p class="notfication-box">
								No Result Found
							</p>
						  </li>
						  
						 </ul>
						 </div>
					  <li class="footer">
						  <a href="#" (click)="redirectToNotification()">View all</a>
					  </li>
					</ul>
				  </li>

				  <li class="d-flex align-items-center" *ngIf="userDetails.name === 'Psychiatrist' || userDetails.name === 'Psychologist' || userDetails.name === 'Counsellor'">
					<div class="d-flex align-items-center manual-dl-container">
						<span class="text-white mb-0 ">Manual</span>
						<button pButton pRipple type="button" icon="pi pi-download" class="p-button-rounded p-button-text download p-button-lg" (click)="downloadManualPdf()"></button>
					</div>
					
				  </li>

				<li class="dropdown user user-menu">
					<a href="#" class="waves-effect waves-light dropdown-toggle w-auto l-h-12 bg-transparent py-0 no-shadow"
						data-bs-toggle="dropdown">
						
						<div class="d-flex pt-0">
							<div class="text-end me-10">
								<p class="pt-5 fs-14 mb-0 fw-700 text-white">{{(dataService.userData |
									async)?.display_name}}</p>
									<!-- <small class="fs-10 mb-0 text-uppercase text-white">{{(dataService.userData |
										async)?.hospital_name}}</small> -->
								<small class="fs-10 mb-0 text-uppercase text-white">{{(dataService.userData |
									async)?.name}}</small>
								
							</div>
							<img class="mr-2" src="assets/images/ico/small-down.svg">
							<img class="avatar rounded-50 ml-3 bg-white h-30 w-30" alt=""
								[src]="(dataService.profilePic | async) || 'assets/images/default-avatar.png'">
						</div>
					</a>

				<ul class="dropdown-menu animated flipInX">
						<li class="user-body">
							<a class="dropdown-item" (click)="openProfile()" style="cursor: pointer;"><i
									class="ti ti-user text-muted me-2"></i> Profile</a>
									<!-- <div class="dropdown-divider"></div>
					 <a class="dropdown-item" (click)="openHierachy()" style="cursor: pointer;"><i
										class="ti ti-settings text-muted me-2"></i> Hierarchy </a>
							<div class="dropdown-divider"></div>  -->
							<a (click)="logout()" style="cursor: pointer;" class="dropdown-item"><i
									class="ti ti-power-off text-muted me-2"></i> Logout</a>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</nav>
</header>
		
<!-- Before Login -->
<div *ngIf="(!excludeHeader(currUrl) && !(auth.currentUser | async)); else LoginHeader">
	<nav *ngIf="!(auth.currentUser | async)" class="navbar navbar-expand-md navbar-light bg-white py-0">
		<ul class="nav ml-auto pr-3 d-md-none">
			<li class="nav-item d-md-none mt-0 py-2 d-lg-flex pl-2">
				<img src="../html/images/logo.svg" height="60" alt="undp-logo">
			</li>
		</ul>
		<div class="navbar-collapse" id="">
			<ul class="navbar-nav align-items-md-center ml-auto">
				<li class="divider--vertical d-none"></li>
				<li class="nav-item d-none d-md-block mt-0 py-2 d-lg-flex pl-2 pr-1">
					<img src="../html/images/logo.svg" height="60" alt="undp-logo">
				</li>

			</ul>
		</div>
	</nav>
</div>
<ng-template #LoginHeader>

</ng-template>
<!-- Before Login -->
