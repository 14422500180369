import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: number): string {
    let returnValue = '0';
    if (value) {
      returnValue = Number(''+value).toLocaleString('en-IN')
    }
    return returnValue;
  }

}
