<app-loader></app-loader>
<router-outlet name="popup"></router-outlet>
<app-generic-popup></app-generic-popup>
<router-outlet name="popup"></router-outlet>


<ng-template [ngIf]="(auth.currentUser | async)" [ngIfElse]="guest">
    <app-header></app-header>
    <app-side-bar></app-side-bar>
  <div class="content-wrapper">
        <ng-container *ngIf="util.getScreenSize()>991" >
            <!-- <mat-drawer-container class="example-container mat-typography" autosize >
                <mat-drawer #drawer mode="side" disableClose="true" opened="true" >
                </mat-drawer>
                <mat-drawer-content> -->
                <div class="example-sidenav-content">
                    <div class="content">
                       
                        <div class="page">
                            <ng-container *ngTemplateOutlet="routertemplate"></ng-container>
                        </div>
                        <!-- <div class="align-items-center bg-theme-blue border-top text-white">
                            <app-footer></app-footer>
                        </div> -->
                    </div>
                </div>
                <!-- </mat-drawer-content>
              </mat-drawer-container> -->
        </ng-container>
        <ng-container  *ngIf="util.getScreenSize()<991">
            <app-side-bar></app-side-bar>
            <div class="content">
                <app-header></app-header>
                <div class="page">
                    <ng-container *ngTemplateOutlet="routertemplate"></ng-container>
                </div>
                <!-- <div class="align-items-center bg-theme-blue border-top text-white">
                    <app-footer></app-footer>
                </div> -->
            </div>
        </ng-container>

  </div>
</ng-template>
<div class="align-items-center  text-white">
    <app-footer *ngIf="showFooter"></app-footer>
</div> 
<ng-template #guest>
  <div class="container-fluid">
      <div class="stage wrapper before-login-content">
          <!-- <div class="row">
              <div class="col-12 before-login-header p-0">
                  <app-header></app-header>
              </div>
          </div> -->
          <ng-container *ngTemplateOutlet="routertemplate"></ng-container>
      </div>
  </div>
 
</ng-template>
<ng-template #routertemplate>
    <router-outlet></router-outlet>
    <!-- <app-chat-bot></app-chat-bot> -->
</ng-template>

