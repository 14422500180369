import { Component, OnInit } from '@angular/core';

import { environment } from 'src/environments/environment';

import { ProfileService } from 'src/app/modules/shared/services/profile.service';
import { AuthService } from 'src/app/screens/auth/services/auth.service';
import { ConfigService } from './modules/shared/services/config.service';
import { DataService } from './modules/shared/services/data.service';
import { UtilsService } from './modules/shared/services';

import { EncDecService } from './modules/shared/services/encryption-decryption.service';
import * as _ from 'lodash';
import { TokenTrackerService } from './screens/auth/services/token-tracker.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

declare global {
  interface Window {
    crossOriginIsolated: boolean;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'emoneeds-frontend-admin';
  env = environment.admin_prefix_url;
  isExpanded= false;
  showFooter = false;
  constructor(private dataService: DataService,
              private configService: ConfigService,
              public auth: AuthService,
              private profileService: ProfileService,
              public util: UtilsService,
              public encDecService: EncDecService,
              private router: Router
              ) {
                this.router.events.pipe(
                  filter(event => event instanceof NavigationEnd)
                ).subscribe((event: NavigationEnd) => {
                  this.showFooter = !event.url.startsWith('/feedback');
                });
              }

  ngOnInit(): void {
    console.log('Release Purpose');
    this.configService.getVersionDetails().subscribe(res => {
      this.dataService.appVersion = res.app_version;
    });
    this.auth.currentUser.subscribe(async (r) => {
      if (r) {
        try {
          const encryptedData = sessionStorage.getItem('userDetails');
          let decryptedData:any = null;
          try {
            if (encryptedData) {
              decryptedData = this.encDecService.get(encryptedData);
            }
          } catch (err) {
            decryptedData=null;
          }
          if (decryptedData) {
            this.dataService.userDetails = JSON.parse(decryptedData);
            const accessControlList = await this.auth.getAccessControlList(this.dataService.userDetails.role_id).toPromise();
            this.dataService.permission = this.dataService.userDetails.permission;
            if (this.dataService.userDetails.profile_picture_url) {
              this.profileService.getProfilePic().subscribe();
            }
            if (this.dataService.userDetails.signature_url) {
              this.profileService.getSignaturePic().subscribe();
            }
            this.dataService.permissions = true;
            this.creatAdminModules(accessControlList);
          } else {
            const userDetails = await this.auth.getLoggedInUserInfo().toPromise();
            const accessControlList = await this.auth.getAccessControlList(userDetails.role_id).toPromise();
            if (userDetails) {
              if (userDetails.profile_picture_url) {
                this.profileService.getProfilePic().subscribe();
              }
              if (userDetails.signature_url) {
                this.profileService.getSignaturePic().subscribe();
              }
              userDetails.module_json = accessControlList;
              userDetails.permission = {};
              if (userDetails.module_json && userDetails.module_json.length > 0) {
                _.each(userDetails.module_json, (menuItem) => {
                  this.updatePermissions(menuItem, userDetails.permission);
                });
              }
              this.dataService.permission = userDetails.permission;
              this.dataService.permissions = true;
              this.dataService.userDetails = userDetails;
              this.creatAdminModules(accessControlList);
              const encryptedData = this.encDecService.set(JSON.stringify(userDetails));
              sessionStorage.setItem('userDetails', encryptedData);
              this.dataService.permissions = true;
            }
          }
        } catch (err) {
          console.log('Error Getting UserDetails');
          const encryptedData = sessionStorage.getItem('userDetails')
          const decryptedData:any = this.encDecService.get(encryptedData);
          this.dataService.userDetails = JSON.parse(decryptedData);
          const accessControlList = await this.auth.getAccessControlList(this.dataService.userDetails.role_id).toPromise();
          this.dataService.permission = this.dataService.userDetails.permission;
          if (this.dataService.userDetails.profile_picture_url) {
            this.profileService.getProfilePic().subscribe();
          }
          if (this.dataService.userDetails.signature_url) {
            this.profileService.getSignaturePic().subscribe();
          }
          this.dataService.permissions = true;
          this.creatAdminModules(accessControlList);
        }
      }
    });

    if (window.crossOriginIsolated) {
      console.log("The application is cross-origin isolated.");
    } else {
      console.log("The application is not cross-origin isolated.");
    }

  }

  creatAdminModules(accessControlList: any) {
    let adminModules = [];
    let adminModulesRoutes = {}
    _.each(accessControlList, (menuItem) => {
      if (menuItem.display_permission == 1) {
        adminModules.push(menuItem.menu_name);
        adminModulesRoutes[menuItem.menu_name] = menuItem.route_url;
      }
    });
    this.dataService.setAdminModule(adminModules);
    this.dataService.setAdminModuleRoute(adminModulesRoutes);
  }

  updatePermissions(menuItem, permission) {
    let menu_name = menuItem.menu_name;
    permission[menu_name] = 'NP';
    if (menuItem.write_permission == 1 && menuItem.read_permission == 1) {
      permission[menu_name] = 'FULL';
    } else if (menuItem.write_permission == 0 && menuItem.read_permission == 1) {
      permission[menu_name] = 'READ';
    } else if (menuItem.write_permission == 1 && menuItem.read_permission == 0) {
      permission[menu_name] = 'FULL';
    }
  }

  
}
