import { Router, NavigationEnd } from '@angular/router';
import { DataService } from './../../services/data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/screens/auth/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RppPatientService } from 'src/app/screens/rpp-patient-list/services/rpp-patient.service';
import { FileUpload } from 'primeng/fileupload';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  visible: boolean;
  supportForm: FormGroup;
  submitted: boolean = false;

  @ViewChild('fileUploadRef', { static: true }) fileUploadRef;

  @ViewChild('fileUpload') fileUpload!: FileUpload;

  isTechSupport: boolean = false;

  constructor(
    public auth: AuthService,
    public dataService: DataService,
    private router: Router,
    private formBuilder: FormBuilder,
    private rppService: RppPatientService,
    private messageService: MessageService
  ) {}

  app_version;
  ngOnInit(): void {
    this.getAppVersion();
    this.initSupportForm();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        //console.log('Current route:', this.router.url);
        //console.log(this.dataService?.userDetails?.module_json);
        this.getPermissionList();
      }
    });
    //this.getPermissionList();
  }

  getAppVersion() {
    this.auth.getAppVersion().subscribe((res) => {
      this.app_version = res.app_version;
    });
  }
  scrollToTop() {
    window.scroll(0, 0);
  }

  excludeHeader(str): boolean {
    return str === '' || /login/g.test(str);
  }

  showDialog() {
    this.visible = true;
  }

  initSupportForm() {
    this.supportForm = this.formBuilder.group({
      query: ['', Validators.required],
      query_imgs: [[]],
    });
  }

  onSelectFile(event): void {
    const files = event.currentFiles;
    const queryImgsControl = this.supportForm.get('query_imgs');

    queryImgsControl.patchValue([]);

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);

      reader.onload = (e) => {
        //console.log(files[i]);
        queryImgsControl.value.push(files[i]);
      };
    }
  }

  submitSupportForm() {
    this.submitted = true;
    if(this.supportForm.valid) {
      const formData = new FormData();
      const supportFormValue = this.supportForm.value;
  
      Object.keys(supportFormValue).forEach((key) => {
        const value = supportFormValue[key];
        
        if(key === 'query_imgs') {
          if(value) {
            for (let i = 0; i < value.length; i++) {
              formData.append(key + '[]', value[i]);
            }
          }
        } else {
          formData.append( key, value);
        }
      });
  
      //console.log(formData, "submit data");
      this.rppService.createSupportQuery(formData).subscribe(
        (res: any) => {
          //console.log(res);
          if(res.status) {
            this.messageService.add({severity:'success', summary: 'Success', detail: 'Ticket created successfully'});
            this.clearImageUpload();
            this.submitted = false;
          }
          
        },
        (err) => {
          //console.log(err);
          this.clearImageUpload();
          this.submitted = false;
        }
        
      );
    }

  }

  clearImageUpload() {
    this.visible = false;
    this.supportForm.reset();
    this.fileUpload.clear();
  }

  isKeyInSessionStorage(key: string): boolean {
    return sessionStorage.getItem(key) !== null;
  }

  checkLogout() {
    const userToken = this.isKeyInSessionStorage('userToken');
    if(!userToken) {
      this.isTechSupport = false;
    }
  }

  async getPermissionList() {    
    if (this.dataService && this.dataService.userDetails && this.dataService.userDetails.module_json) {
      const techSupportIdx = this.dataService.userDetails.module_json.findIndex(x => x.menu_id === 30);
      if (techSupportIdx > -1) {
        const isTechSupport = this.dataService.userDetails.module_json[techSupportIdx].display_permission;
        this.isTechSupport = isTechSupport;
      }
    }
  }
}
