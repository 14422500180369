<form [formGroup]="changeMobileForm" (ngSubmit)="onSubmit()">
  <div class="container-fluid">
    <!-- <div class="bg-light mt-3 mb-3">
        <h4 class="cutom-space box-title my-2">
            Mobile Number
      </h4>
      </div> -->

  <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-12">
        <div class="form-group"  *ngIf="!otpSent">
            <label for="" class="form-label">Mobile Number</label>
            <input class="form-control" [appRange]="[1000000000, 9999999999]"
            [appMin]="1000000000" type="number" appMobileNumber="true" [appMax]="9999999999"
            formControlName="mobileNumber"
            [ngClass]="{'invalid-field': formSubmitted && changeMobileForm.get('mobileNumber').hasError('required')}">
            <div *ngIf="formSubmitted && formControls.mobileNumber.errors" class="invalid-feedback">
                <div *ngIf="formControls.mobileNumber.errors.required">Moible number is required</div>
                    <div *ngIf="formControls.mobileNumber.errors.range">Must be 10 digits</div>
            </div>
        </div>
      </div>
      <!-- <div class="col-lg-4 col-md-4 col-sm-12">
        <div class="form-group">
            <label for="" class="form-label">New Mobile Number*</label>
            <input  class="form-control"
            [appRange]="[1000000000, 9999999999]"
            type="tel"
            maxlength="10"
            numbersOnly
            appMobileNumber="true"
            [appMin]="1000000000"
            [appMax]="9999999999"
            [ngClass]="{'invalid-field': formSubmitted && changeMobileForm.get('newMobileNumber').hasError('required')}"
            formControlName="newMobileNumber">
            <div *ngIf="formSubmitted && formControls.newMobileNumber.errors && formControls.newMobileNumber.errors.range" class="invalid-feedback"
             tooltip="Must be 10 digits" placement="top">Must be 10 digits</div>
        </div> -->
    </div>
    <!-- <div class="col-lg-4 col-md-4 col-sm-12">
        <div class="form-group"  *ngIf="otpSent">
            <label for="" class="form-label">OTP*</label>
            <input type="tel" maxlength="6" numbersOnly class="form-control"
            [appRange]="[111111, 999999]" [appMin]="111111" [appMax]="999999"
            [ngClass]="{'invalid-field': formSubmitted && changeMobileForm.get('otp').hasError('required')}"
            formControlName="otp">
            <div *ngIf="formSubmitted && formControls.otp.errors && formControls.otp.errors.required" class="invalid-feedback"
             tooltip="Please enter OTP" placement="top">Please enter OTP</div>
            <div *ngIf="formSubmitted && formControls.otp.errors && !formControls.otp.errors.required && formControls.otp.errors.range" class="invalid-feedback"
             tooltip="Must be 6 digits" placement="top">Must be 6 digits</div>
             <div  *ngIf="otpSent" [ngClass]="{'marginTop10': formSubmitted && !changeMobileForm.get('otp').valid}">{{(resendTimer ? resendTimer + ' sec ' : '')}} <a [ngClass]="{'resend-txt-active': resentOtp, 'resend-txt-inactive': !resentOtp}" (click)="sendOtp()">Resend OTP</a></div>
        </div>
    </div> -->
     
    <!-- <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="box-footer text-end">
            <label for="" class="invisible"> &nbsp;</label>
            <button *ngIf="!otpSent" type="submit" class="btn btn-primary btn-block btn-md update-pass">Verify Mobile Number</button>
            <button *ngIf="otpSent" type="submit" class="btn btn-primary btn-md btn-block update-pass">Change Mobile Number</button>
    
        </div>
    </div>

   
 

  </div> -->

  </div>
</form>
