<!-- START : sidebar  -->
<aside class="main-sidebar">
    <!-- sidebar-->
    <section class="sidebar position-relative">
	  	<div class="multinav">
		  <div class="multinav-scroll" style="height: 100%;">
			  <!-- sidebar menu-->
			  <ul class="sidebar-menu" data-widget="tree">
                  <li class="treeview" *ngFor="let menuItem of sideNavBarMenuItems">
                    <ng-container *ngIf="!menuItem.isFunction else callfunctionOnMenuClick">
                            <a title="{{menuItem.menuLabel}}" data-toggle="tooltip" data-placement="right" routerLinkActive="active" [routerLink]="menuItem.link" *ngIf="menuItem.permission">
                                <i class="{{menuItem.iconClass}}"></i>
                                <span>{{menuItem.menuLabel}}</span>
                            </a>
                        </ng-container>
                        <ng-template #callfunctionOnMenuClick>
                            <a title="{{menuItem.menuLabel}}" data-toggle="tooltip" data-placement="right" routerLinkActive="active" (click)='callMenuFucntion(menuItem.link)' *ngIf="menuItem.permission">
                                <i class="{{menuItem.iconClass}}"></i>
                                <span>{{menuItem.menuLabel}}</span>
                            </a>
                        </ng-template>
                </li>

               
			  </ul>


		  </div>
		</div>
    </section>
  </aside>
<!-- <ng-container *ngIf="util.getScreenSize()<991">
    <div class="sidebar d-none">
        <div class="list-group">
            <a class="list-group-item text-center border-0 list-group-item-action my-2 py-2 sidebar-toggler text-white">
                <span class="icon-align-right-solid"></span>
            </a>
            <ng-container *ngFor="let menuItem of sideNavBarMenuItems">
                <ng-container *ngIf="!menuItem.isFunction else callfunctionOnMenuClick">
                    <a routerLinkActive="active" [routerLink]="menuItem.link" *ngIf="menuItem.permission" [tooltip]='menuItem.menuLabel' placement='right' container='body' class="list-group-item text-center border-0 list-group-item-action">
                        <span [class]="menuItem.iconClass"></span>
                    </a>
                </ng-container>
                <ng-template #callfunctionOnMenuClick>
                    <a routerLinkActive="active" (click)='callMenuFucntion(menuItem.link)' *ngIf="menuItem.permission" [tooltip]='menuItem.menuLabel' placement='right' container='body' class="list-group-item text-center border-0 list-group-item-action">
                        <span [class]="menuItem.iconClass"></span>
                    </a>
                </ng-template>
            </ng-container>

        </div>
        <span class="inner-curve inner-curve--top"></span>
        <span class="inner-curve inner-curve--bottom "></span>
    </div>
    <div class="sidebar-popup position-fixed" style="display: none;">
        <div class="list-group bordr-0">
            <div class="userdetailsmob media media-user bg-transparent rounded-0 border-0 border-bottom border-right-0  list-group-item-action">
                <img class="media-user__pic rounded-circle mr-2" width="36" [src]="(dataService.profilePic | async) || 'assets/images/default-avatar.png'">
                <div class="media-body">
                    <div class="media-user__name">{{(dataService.userData | async)?.display_name}}</div>
                    <div class="media-user__name">{{(dataService.userData | async)?.role}} | <span *ngIf="(dataService.userData | async)?.user_level == 'National'"> &nbsp;| India</span>
                        <span *ngIf="(dataService.userData | async)?.user_level == 'State' || (dataService.userData | async)?.user_level == 'District' || (dataService.userData | async)?.user_level == 'Block'">{{(dataService.userData | async)?.state}}</span>
                        <span *ngIf="(dataService.userData | async)?.user_level == 'District' || (dataService.userData | async)?.user_level == 'Block'">&nbsp;| {{(dataService.userData | async)?.district}}</span>
                        <span *ngIf="(dataService.userData | async)?.user_level == 'Block'">&nbsp;| {{(dataService.userData | async)?.block}}</span>
                    </div>
                    <div class="media-user__tel">{{(dataService.userData | async)?.mobile_number}}</div>
                </div>
            </div>
            <div class="menu-list">
                <ng-container *ngFor="let menuItem of sideNavBarMenuItems">
                    <ng-container *ngIf="!menuItem.isFunction else callfunctionOnMenuClick">
                        <a (click)="sidebarToggle()" routerLinkActive="active" [routerLink]="menuItem.link" *ngIf="menuItem.permission" class="list-group-item bg-transparent rounded-0 border-left-0 border-bottom border-top border-right-0 px-0 list-group-item-action">
                            <span [class]="menuItem.iconClass"></span><span class=""> {{menuItem.menuLabel}}</span>
                        </a>
                    </ng-container>
                    <ng-template #callfunctionOnMenuClick>
                        <a (click)="sidebarToggle()" routerLinkActive="active" (click)='callMenuFucntion(menuItem.link)' *ngIf="menuItem.permission" class="list-group-item bg-transparent rounded-0 border-left-0 border-bottom border-top border-right-0 px-0 list-group-item-action">
                            <span [class]="menuItem.iconClass"></span><span class=""> {{menuItem.menuLabel}}</span>
                        </a>
                    </ng-template>
                </ng-container> -->

                <!-- <a (click)="logout()" style="cursor: pointer;" class="list-group-item bg-transparent rounded-0 border-left-0 border-bottom border-top border-right-0 px-0 list-group-item-action">
                    <span class="icon-in-alt"></span><span class=""> Logout</span>
                </a> -->
            <!-- </div>
        </div>
        <a class="btn btn-light rounded-pill btn-slider-close p-0 position-absolute" (click)="sidebarToggle()">
            <span class="icon-cheveron-left"></span>
        </a>
    </div>

</ng-container> -->

<!-- <ng-container *ngIf="util.getScreenSize()>991">
    <div class="sidebar" [ngClass]="{'menuOpen':isExpanded, 'menuClose':!isExpanded}">

        <div class="hamburgercover"> -->
            <!-- <div class="logoplace">
                <img src="assets/images/national-emblem.svg" alt="national-emblem" class="national-emblem">
                <a class="navbar-brand ml-0" [routerLink]="['/']"> <img class="cowinlogocls" src="assets/images/cowin-login-logo-blue.png" alt="Covid logo"> </a>

            </div> -->
            <!-- <button mat-mini-fab (click)="isExpanded = !isExpanded" color="warn" style="margin: 10px;" class="hamburgermenu list-group-item text-center border-0 list-group-item-action my-2 py-2 sidebar-toggler text-white">
		<span class="icon-align-right-solid"></span>
             </button> -->

            <!-- <a *ngIf="isExpanded" class="desktop-arrow-btn btn btn-light rounded-pill btn-slider-close p-0 position-absolute" (click)="isExpanded = !isExpanded">
            <span class="icon-cheveron-left"></span>
        </a> -->
<!--
        </div> -->

        <!-- <div class="list-group">
            <ng-container *ngIf="isExpanded"> -->
                <!-- <div class="media media-user bg-transparent rounded-0 border-bottom pb-2  border-right-0  list-group-item-action">
                    <img class="media-user__pic rounded-circle mr-2" width="36" [src]="(dataService.profilePic | async) || 'assets/images/default-avatar.png'">
                    <div class="media-body text-white">
                        <div class="media-user__name">{{(dataService.userData | async)?.display_name}}</div>
                        <div class="media-user__name">{{(dataService.userData | async)?.role}}
                            <span *ngIf="(dataService.userData | async)?.user_level == 'National'"> India</span>
                            <span *ngIf="(dataService.userData | async)?.user_level == 'State' || (dataService.userData | async)?.user_level == 'District' || (dataService.userData | async)?.user_level == 'Block'">{{(dataService.userData | async)?.state}}</span>
                            <span *ngIf="(dataService.userData | async)?.user_level == 'District' || (dataService.userData | async)?.user_level == 'Block'">&nbsp;| {{(dataService.userData | async)?.district}}</span>
                            <span *ngIf="(dataService.userData | async)?.user_level == 'Block'">&nbsp;| {{(dataService.userData | async)?.block}}</span>
                            <div class="media-user__tel">{{(dataService.userData | async)?.mobile_number}}</div>
                        </div>
                    </div>

                </div> -->
                <!-- <div class=" text-white border-bottom list-state-group">

          </div> -->
            <!-- </ng-container>
            <mat-nav-list class="cutommatlist">

                <ng-container *ngFor="let menuItem of sideNavBarMenuItems">
                    <mat-list-item *ngIf="menuItem.permission">
                        <ng-container *ngIf="!isExpanded">
                            <ng-container *ngIf="!menuItem.isFunction else callfunctionOnMenuClick">
                                <a routerLinkActive="active" [routerLink]="menuItem.link" [matTooltip]="menuItem.menuLabel" class="list-group-item text-center border-0 list-group-item-action">
                                    <span [class]="menuItem.iconClass"></span>
                                </a>
                            </ng-container>
                            <ng-template #callfunctionOnMenuClick>
                                <a routerLinkActive="active" (click)="callMenuFucntion(menuItem.link)" [matTooltip]="menuItem.menuLabel" class="list-group-item text-center border-0 list-group-item-action">
                                    <span [class]="menuItem.iconClass"></span>
                                </a>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngIf="isExpanded">
                            <ng-container *ngIf="!menuItem.isFunction else callfunctionOnMenuClick">
                                <a routerLinkActive="active" [routerLink]="menuItem.link" *ngIf="menuItem.permission" class="list-group-item-expand bg-transparent rounded-0 border-left-0 border-bottom  border-right-0 px-0 list-group-item-action">
                                    <span [class]="menuItem.iconClass"></span><span class=""> {{menuItem.menuLabel}}</span>
                                </a>
                            </ng-container>
                            <ng-template #callfunctionOnMenuClick>
                                <a routerLinkActive="active" (click)="callMenuFucntion(menuItem.link)" *ngIf="menuItem.permission" class="list-group-item-expand bg-transparent rounded-0 border-left-0 border-bottom  border-right-0 px-0 list-group-item-action">
                                    <span [class]="menuItem.iconClass"></span><span class=""> {{menuItem.menuLabel}}</span>
                                </a>
                            </ng-template>
                        </ng-container>
                    </mat-list-item>

                </ng-container>
                <mat-list-item>
                    <!-- <a (click)="logout()" *ngIf="!isExpanded" style="cursor: pointer;" class="list-group-item text-center border-0 list-group-item-action" [matTooltip]="'Logout'" container='body' placement='right'>
                        <span class="icon-in-alt"></span>

                    </a> -->
                    <!--  <a (click)="logout()" *ngIf="isExpanded" style="cursor: pointer;" class="list-group-item-expand bg-transparent rounded-0 border-left-0 border-right-0 px-0 list-group-item-action">
                        <span class="icon-in-alt"></span><span class=""> Logout</span>
                    </a> -->

                <!-- </mat-list-item>
            </mat-nav-list> --> 


            <!-- <mat-nav-list class="cutommatlist cutommatlist_new">
                      <mat-list-item>
                        <a  class="list-group-item text-center border-0 list-group-item-action">
                            <span class="icon-document-diagrams"></span>
                            Dashboard
                         </a>
                      </mat-list-item>

                       <mat-list-item>
                       <a class="list-group-item text-center border-0 list-group-item-action">
                           <span class="icon-document-diagrams"></span>
                            Report
                        </a>
                      </mat-list-item>


                         <mat-list-item>
                            <a [matMenuTriggerFor]="belowMenu" class="list-group-item text-center border-0 list-group-item-action">
                              <span class="icon-document-diagrams"></span>
                              Report
                              </a>

                            <mat-menu #belowMenu="matMenu" yPosition="below">
                            <button mat-menu-item>Item 1</button>
                            <button mat-menu-item>Item 2</button>
                            </mat-menu>
                         </mat-list-item>


                       <mat-list-item>
                          <a class="list-group-item text-center border-0 list-group-item-action">
                            <span class="icon-Facility-address"></span>
                            Cold Chain Points
                          </a>
                      </mat-list-item>

                       <mat-list-item>
                            <a class="list-group-item text-center border-0 list-group-item-action">
                            <span class="icon-management"></span>
                             Vaccination Centers
                             </a>
                      </mat-list-item>

                       <mat-list-item>
                            <a class="list-group-item text-center border-0 list-group-item-action">
                            <span class="icon-benificiary"></span>
                             Recipients
                             </a>
                      </mat-list-item>

                       <mat-list-item>
                           <a class="list-group-item text-center border-0 list-group-item-action">
                            <span class="icon-benificiary"></span>
                            Users
                            </a>
                      </mat-list-item>
              </mat-nav-list> -->
<!--
        </div>
    </div>



</ng-container> -->


<!-- END : sidebar  -->
