import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';

import { Observable } from 'rxjs';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor() { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Check if the request has the custom header for No Interceptor
        if (request.headers.has('No-Intercept')) {
            // Clone the request and remove the custom header
            const headers = request.headers.delete('No-Intercept');
            const cloneReq = request.clone({ headers });
            return next.handle(cloneReq);
        }

        const userToken = sessionStorage.getItem('userToken');
        if (userToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: JSON.parse(userToken)
                }
            });
        }
        return next.handle(request);
    }
}
