<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    [config]="{ignoreBackdropClick: true, keyboard: false}" [class.right]="position === modalPositionEnum.RIGHT">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            
                <ng-container *cdkPortalOutlet></ng-container>
            

        </div>
    </div>
</div>