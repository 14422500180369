import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProfileService, DataService, UtilsService } from '../../../services';
import { AuthService } from 'src/app/screens/auth/services/auth.service';
import { EncDecService } from '../../../services/encryption-decryption.service';

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss']
})
export class ProfileFormComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  model: any = {};
  profileForm: FormGroup;
  today = new Date();
  genderObj;
   userInfo
  signatures = {
    '/9j/4AAQS': 'image/jpg',
    '/9j/4Qxi': 'image/jpg',
    iVBORw0KGgo: 'image/png'
  };

  uploadedFileNameValidation = /^[ A-Za-z0-9_\-]*$/;

  constructor(private profileService: ProfileService,
              public dataService: DataService,
              private util: UtilsService,
              private cdr: ChangeDetectorRef,
              private auth: AuthService,
              private encDecService: EncDecService) { }

  ngOnInit(): void {
    
    this.model = Object.assign({}, this.dataService.userDetails);
    //this.model.dateOfBirth = this.model.date_of_birth ? new Date(this.model.date_of_birth) : null;
     //this.profileService.getGenderList().subscribe(res => this.genderObj = res);
    this.initForm();
    this.profileService.getLoggedInUserInfo().subscribe((res)=>{
      this.userInfo = res
      console.log("data is", this.userInfo);
      
    })
  }

  initForm(): void {
    this.profileForm = new FormGroup({
      displayName: new FormControl(this.model.display_name, Validators.required),
      // gender: new FormControl(this.model.gender),
      // dateOfBirth: new FormControl(this.model.date_of_birth),
      // stateId: new FormControl({value: this.model?.state_id, disabled: true}),
      // districtId: new FormControl({value: this.model?.district_id, disabled: true}),
      // blockId: new FormControl({value: this.model?.block_id, disabled: true}),
      // cityVillageId: new FormControl({value: this.model?.village_id, disabled: true})
    });
  }
  
  // let userid = (this.userDetails.user_id).toString();
  // const userId = this.encDecService.set(userid);

  onSelectFile(event): void {
    
    if (event.target.files && event.target.files[0]) {
        const userid = (this.dataService.userDetails.user_id).toString();
        const userId = this.encDecService.set(userid);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (e: any) => {
          const mimeType = this.detectMimeType(reader.result);
          if (mimeType) {
            const name = event.target.files[0].name;
            const fileNameWithOutExtension = name.substring(0, name.lastIndexOf('.'));
            if (this.uploadedFileNameValidation.test(fileNameWithOutExtension)) {
              const formData = new FormData();
              formData.append('file',  event.target.files[0]);
              formData.append('userId',  userId);
              this.profileService.saveProfilePic(userId, formData)
              .subscribe(response => {
                const reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]); // read file as data url
                reader.onload = (e) => { // called once readAsDataURL is completed
                  this.dataService.setProfilePic(e.target.result);
                  this.util.showSuccessToast('Profile Picture Uploaded Successfully');
                };
              }, error => {
              if (error.status === 400){
                // this.util.showErrorToast(this.translate.instant('Format Not Supported'));
              } else {
                this.util.showErrorToast('Error In Profile Picture Upload');
              }});
            } else {
              event.target.files = null;
              this.util.showErrorToast(`Invalid file name`);
            }
          } else {
            event.target.files = null;
            this.util.showErrorToast('Invalid file type, allowed extensions are PNG,JPEG,JPG');
          }
        };
    }
  }

  detectMimeType(b64) {
    for (const s in this.signatures) {
      if (b64.indexOf(s) > 0) {
        return this.signatures[s];
      }
    }
  }

  onSubmit(): void {
    this.profileForm.markAllAsTouched();
  
    if (!this.profileForm.valid) { return; }
  
    const model = {
      display_name: this.profileForm.get('displayName').value,
      // Add other fields as necessary
    };
  
    this.profileService.updateProfileDetails(this.model.user_id, model)
      .subscribe(async (res) => {
        // Immediately update DataService and session storage with new display name
        this.dataService.userDetails.display_name = model.display_name;
  
        const encryptedData = this.encDecService.set(JSON.stringify(this.dataService.userDetails));
        sessionStorage.setItem('userDetails', encryptedData);
  
        this.util.showSuccessToast('Profile Updated Successfully');
        this.cancel();
  
        // Fetch the latest user details and update DataService and session storage
        const userDetails = await this.auth.getUserDetails().toPromise();
        if (userDetails) {
          this.dataService.userDetails = userDetails;
          this.dataService.permission = userDetails.permission;
          if (userDetails.profile_picture_url) {
            this.profileService.getProfilePic().subscribe();
          }
          const encryptedData = this.encDecService.set(JSON.stringify(userDetails));
          sessionStorage.setItem('userDetails', encryptedData);
          this.dataService.permissions = true;
        }
      });
  }
  

  locationChange(location): void {
    this.model = {...this.model, ...location};
    Object.keys(this.model).forEach((key) => (!this.model[key]) && delete this.model[key]);
    this.cdr.detectChanges();
  }


  cancel(): void {
    this.closeModal.emit();
  }

}
