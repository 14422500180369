<div class="container-fluid">
    <div class="bg-light mt-3 mb-3">
        <h4 class="cutom-space box-title my-2">
            Add Signature
        </h4>
    </div>
    <div class="container-fluid profile-form">
        <div class="align-items-center row">

            <div class="col-auto col-md-3 form-group">
                <div class="float-left position-relative signature-pic">
                    <img class=" img-signature" [src]="(dataService.signaturePic | async) || 'assets/images/signature.png'">
                    <div class="btn btn-outline-primary btn-sm btn-wave btn-circle edit-action-holder position-absolute d-block">
                        <span class="icon-pencil"><i class="ti ti-pencil"></i>
                        </span>
                        <input type="file" name="Select File" enctype="multipart/form-data" (change)="onSelectFile($event)"
                                accept="image/*" />
                    </div>
                </div>





<!-- 
            <div class="col-auto col-md-3 form-group">
                <img class="img-signature"
                    [src]="(dataService.signaturePic | async) || 'assets/images/default-avatar.png'">
                    <div class="signature">
                        <span class="btn btn-primary btn-sm btn-wave btn-circle edit-action-holder">
                            <span class="icon-pencil"><i class="ti ti-pencil"></i></span>
                            <input type="file" name="Select File" enctype="multipart/form-data" (change)="onSelectFile($event)"
                                accept="image/*" />
                        </span>
                    </div>
          
            </div> -->
        </div>
    </div>
</div>