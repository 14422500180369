<!-- Chat bot UI start -->
<div class="chat-screen show-chat">
  <div class="chat-header hide" id="chat_header" >
      <div class="chat-header-title">
          Co-WIN Support Desk- We're online
      </div>
      <!-- <div class="chat-header-option hide" id="chat_header_option" >
          <span class="dropdown custom-dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
              </a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink1" style="will-change: transform;">
                  <a class="dropdown-item" href="javascript:void(0);">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#bc32ef" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                      Send Transcriptions
                  </a>
                  <a class="dropdown-item end-chat" href="javascript:void(0);">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#bc32ef" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-power"><path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path><line x1="12" y1="2" x2="12" y2="12"></line></svg>
                      End Chat
                  </a>
              </div>
          </span>
      </div> -->
  </div>
  <div class="chat-mail hide" id="chat_mail">

      <div class="row">
          <div class="col-md-12">
              <div class="form-group">
                  <input type="text" class="form-control" #personName placeholder="Name" name="person_name" id="person_name">
              </div>
          </div>
          <div class="col-md-12">

          </div>
          <div class="col-md-12">

          </div>
          <div class="col-md-12">
              <button class="btn btn-primary btn-rounded btn-block" (click)="validateParam();">Start Chat</button>
          </div>

      </div>
  </div>
  <div class="chat-body hide" id="chat_body" >
      <div id="chat_history">
          <div class="chat-start">Monday, 1:27 PM</div>
          <div class="chat-bubble me" id="showP">Welcome to CoWin Helpdesk, if you need help simply reply to this message, we are online and ready to help.</div>
     </div>
      <div id="writing" [ngClass]="{hide: !showHideWriteMessage, show: showHideWriteMessage}" class="chat-bubble you">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto;display: block;shape-rendering: auto;width: 43px;height: 20px;" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <circle cx="0" cy="44.1678" r="15" fill="#ffffff">
                  <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="57.5;42.5;57.5;57.5" keyTimes="0;0.3;0.6;1" dur="1s" begin="-0.6s"></animate>
              </circle> <circle cx="45" cy="43.0965" r="15" fill="#ffffff">
              <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="57.5;42.5;57.5;57.5" keyTimes="0;0.3;0.6;1" dur="1s" begin="-0.39999999999999997s"></animate>
          </circle> <circle cx="90" cy="52.0442" r="15" fill="#ffffff">
              <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="57.5;42.5;57.5;57.5" keyTimes="0;0.3;0.6;1" dur="1s" begin="-0.19999999999999998s"></animate>
          </circle></svg>
      </div>
  </div>
  <div class="chat-input hide" id="chat_input" >
      <input type="text" placeholder="Type a message..." #sendMessage id="send_msg" name="send_msg">
      <div class="input-action-icon">
          <!-- <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path></svg></a> -->
          <a href="javascript:void(0)" (click)="send_message();"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg></a>
      </div>
  </div>
  <div class="chat-session-end hide" id="chat_session_end" >
      <h5>This chat session has ended</h5>
      <!-- <div class="rate-me">
         <div class="col-md-12">
              <button class="btn btn-primary btn-rounded btn-block" (click)="start_chat()">Start Chat</button>
          </div>
      </div> -->
  </div>
</div>
<div class="chat-bot-icon close_chat hide" id="close_chat" (click)="close_chat()" >
  <span style="color: #fff; padding: 0; font-size: 24px;" >X</span>
</div>
<div class="chat-bot-icon" id="open_chat" (click)="open_chat()" >
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square animate"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
</div>

<!-- Chat Bot UI Ends -->
