
<form name="form" class="form" [formGroup]='profileForm' (ngSubmit)="onSubmit()" novalidate>
    <div class="container-fluid profile-form">
       
        <div class="align-items-center row">
            <div class="col-auto col-md-3 form-group">
                <div class="float-left position-relative profile-pic">
                    <img class="img-thumbnail rounded-circle" height="114" width="114" [src]="(dataService.profilePic | async) || 'assets/images/default-avatar.png'">
                    <span  class="btn btn-primary btn-sm btn-wave btn-circle edit-action-holder position-absolute d-block">
                        <span class="icon-pencil" ><i class="ti ti-pencil"></i></span>
                        <input name="Select File" title=" " type="file" (change)="onSelectFile($event)" accept="image/*" />
                    
                    </span>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                    <label for="" class="form-label">Name*</label>
                    <input type="text" [appRangeLength]="[2, 30]" formControlName='displayName'
                    appInputChar='username' class="form-control">
                    <div *ngIf="profileForm.get('displayName')?.errors?.rangeLength" class="invalid-feedback"
                    tooltip="Must be between 2-30 characters"
                    placement="bottom">Must be between 2-30 characters</div>
                    <div *ngIf="!profileForm.get('displayName')?.errors?.rangeLength && profileForm.get('displayName')?.errors?.inputcharvalidator" class="invalid-feedback"
                    tooltip="Must contain only English Alphabets (“a-z” and “A-Z”), Dot “.”, Hyphen “-”, Open parenthesis “(“ and Close parenthesis “)”"
                    placement="bottom"
                    >Must contain only English Alphabets (“a-z” and “A-Z”), Dot “.”, Hyphen “-”, Open parenthesis “(“ and Close parenthesis “)”</div>
    
                </div>
            </div>
            
            <div class="col-lg-5 col-md-5 col-sm-12 text-end mt-3">
                <button type="submit" class="btn btn-primary w-100 btn-md me-3">Save</button>
                <button (click)="cancel()" type="button" class="btn btn-danger w-100 btn-md">Cancel</button>
            </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-4 col-md-4 col-sm-12" *ngIf="userInfo?.name">
                    <span><b>Role Name: </b>{{userInfo.name}}</span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12" *ngIf="userInfo?.org_name">
                    <span><b>Organization Name: </b>{{userInfo.org_name}}</span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12" *ngIf="userInfo?.hospital_name">
                    <span><b>Hospital Name: </b>{{userInfo.hospital_name}}</span>
                </div>
            </div>
            <div class="form-group col-md-5 mb-0">
                <div class="form-row">
                    <!-- <div class="form-group col-md-5">
                        <label for="">Gender</label>
                        <mat-select formControlName='gender' class="form-control form-control-sm">
                            <mat-option *ngFor="let gender of genderObj"  [value]="gender.id">
                            {{ gender.gender }}
                            </mat-option>
                        </mat-select>
                    </div> -->
                    <!-- <div class="form-group col-md-7">
                        <label for="">Date of Birth</label>
                        <ejs-datepicker  [showTodayButton]=false locale='en-US' [allowEdit]="false"
                        formControlName='dateOfBirth' format="dd/MM/yyyy" class="form-control form-control-sm pt-0 e-custom-style"
                        [max]='today'></ejs-datepicker>
                        <div class="invalid-feedback" *ngIf="profileForm.get('dateOfBirth').errors?.max" tooltip="Invalid date"
                        placement="bottom">Invalid date</div>
                    </div> -->
                </div>
            </div>
            <div class="d-none d-md-block mb-3 w-100 mt-2"></div>
            <!-- [cityVillageId]="model?.village_id" -->
            <!-- <div class="form-group col-md-12">
                <app-common-location-fields [stateId]="model?.state_id" [districtId]="model?.district_id"
                    [blockId]="model?.block_id" [parentForm]='profileForm' [stateDisable]='true' [districtDisable]='true'
                    [blockDisable]='true' [villageDisable]='true'>
                </app-common-location-fields>
            </div> -->
    
      
    </div>
</form>
