import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  q = '';
  sfSubmitted = false;
  constructor() { }

  ngOnInit(): void {
  }

  onSubmit(q, f): void {
    this.sfSubmitted = true;
    if (!q || q.trim() === '') { this.q = ''; return; }
    // window.open(`${environment.v1_url}/service-request?request=search&q=${encodeURIComponent(q)}&key=${JSON.parse(sessionStorage.getItem('userToken'))}`);
  }

  dataChange(q, f: NgForm): void {
    f.form.markAsUntouched();
    this.sfSubmitted = false;
    if (!q || q.trim() === '') { this.q = ''; return; }
  }

}
