import { Observable } from 'rxjs';
import { environment  } from 'src/environments/environment';
import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { UtilsService, LocationService } from 'src/app/modules/shared/services';
@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SelectLocationComponent implements OnInit, OnChanges {

  items: Observable<any[]>;
  item: Observable<any>;


  stateList: Observable<any[]>;
  districtList: Observable<any[]>;
  blockList: Observable<any[]>;
  villageList: Observable<any[]>;
  model: any = {};
  value: string;

  @Input() sRequired: boolean;
  @Input() dRequired: boolean;
  @Input() bRequired: boolean;
  @Input() vRequired: boolean;
  @Input() stateVisible: boolean =  true;
  @Input() districtVisible: boolean =  true;
  @Input() blockVisible: boolean =  true;
  @Input() villageVisible: boolean =  true;
  @Input() stateDisable: boolean =  false;
  @Input() districtDisable: boolean =  false;
  @Input() blockDisable: boolean =  false;
  @Input() villageDisable: boolean =  false;
  @Input() stateSelected: string;
  @Input() districtSelected: string;
  @Input() blockSelected: string;
  @Input() villageSelected: string;
  @Input() isAdd: boolean;
  @Input() isDisable: boolean = false;
  @Output() valueChange = new EventEmitter();

  constructor(
    private locServ: LocationService,
    ) {
  }

  ngOnChanges(changes) {
    if (changes.stateSelected) {
      this.model.state = changes.stateSelected.currentValue;
      this.stateChange(this.model.state);
    }
    if (changes.districtSelected) {
      this.model.district = changes.districtSelected.currentValue;
      this.districtChange(this.model.district);
    }
    if (changes.blockSelected) {
      this.model.block = changes.blockSelected.currentValue;
      this.blockChange(this.model.block);
    }
  }

  ngOnInit() {

    this.stateList = this.locServ.getStates();

    this.valueChanged();
  }

  reload () {
    this.model.state = null;
    this.stateList = this.locServ.getStates();
    this.stateChange(null);
  }

  stateChange(state) {
    this.model.district = null;
    this.model.block = null;
    this.model.village = null;
    this.districtList = null;
    this.blockList = null;
    if (state) {
      this.value = null;
      this.districtList = this.locServ.getDistricts(state);
    } else {
      this.model.block = null;
    }
    this.model.geo_id = state;
    this.valueChanged();
  }

  districtChange(district) {
    this.model.block = null;
    this.model.village = null;
    this.blockList = null;
    if (district) {
      this.value = null;
      this.blockList = this.locServ.getBlocks(district);
    } else {
      this.model.district = null;
    }
    this.model.geo_id = district;
    this.valueChanged();
  }

  blockChange(block) {
    this.model.village = null;
    this.villageList = null;
    if (block) {
      this.value = null;
    } else {
      this.model.block = null;
    }
    this.model.geo_id = block;
    this.valueChanged();
  }


  valueChanged() {
    this.valueChange.emit(this.model);
  }
}
