<form [formGroup]="changePasswordForm" (ngSubmit)="updatePassword()">
  <div class="container-fluid">
    <div class="bg-light mt-3 mb-3">
      <h4 class="cutom-space box-title my-2">
        Change password
    </h4>
    </div>
  
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12">
          <div class="form-group">
            <label for="" class="form-label">Current Password*</label>
            <input type="password" class="form-control" formControlName="currentPassword" [ngClass]="{'invalid-field': formSubmitted && changePasswordForm.get('currentPassword').hasError('required')}"
            appNospace="true" >
            <div *ngIf="formSubmitted && formControls.currentPassword.errors" class="invalid-feedback">
                    <div *ngIf="formControls.currentPassword.errors.minlength">Password should be min 6 chars</div>
                    <div *ngIf="formControls.currentPassword.errors.maxlength">Password should be max 20 chars</div>
            </div>
        </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12">
          <div class="form-group">
            <label for="" class="form-label">New Password*</label>
            <input type="password" class="form-control" autocomplete="newPassword_off"
            [ngClass]="{'invalid-field': formSubmitted && changePasswordForm.get('newPassword').hasError('required')}"
            [popover]='passwordPolicyTemplate'
            triggers="focus:mouseleave"
            placement="right"
            data-container="body"
            formControlName="newPassword" appNospace 
            (ngModelChange)="onTextChange($event)">
           
            <div *ngIf="formSubmitted && formControls.newPassword.errors" class="invalid-feedback">
                 <div *ngIf="formControls.newPassword.errors.minlength">Password should be min 6 chars</div>
                 <div *ngIf="formControls.newPassword.errors.maxlength">Password should be max 20 chars</div>
                 <div *ngIf="formControls.newPassword.errors.pattern">Password Must Satisfy the Given Password Policy</div>
            </div>
        </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12">
          <button type="submit" class="btn btn-primary btn-block btn-md update-pass">Update Password</button>
        </div>
        
      
          
      </div>

  </div>
</form>
<ng-template #passwordPolicyTemplate>
  <ul style="list-style: none;" class="passwordpolicy-list-profile">
    <li *ngIf="passwordPolicyData.min_password_length">
      <i class="fa "
      [ngClass]='{"text-red fa-times-circle-o": !passwordPolicyData.is_min_password_length, "text-green fa-check-circle-o": passwordPolicyData.is_min_password_length}'
      aria-hidden="true"></i>
      <span style="padding-left: 3px;">Minimum Password length must be {{ passwordPolicyData.min_password_length }}</span>
    </li>
    <li *ngIf="passwordPolicyData.alphabetical === 1">
      <i class="fa "
      [ngClass]='{"text-red fa-times-circle-o": !passwordPolicyData.is_alphabetical, "text-green fa-check-circle-o": passwordPolicyData.is_alphabetical}'
      aria-hidden="true"></i>
      <span style="padding-left: 3px;">Must Contain mix of uppercase (A-Z) and lowercase (a-z) alphabets</span>
    </li>
    <li *ngIf="passwordPolicyData.numeric === 1">
      <i class="fa "
      [ngClass]='{"text-red fa-times-circle-o": !passwordPolicyData.is_numeric, "text-green fa-check-circle-o": passwordPolicyData.is_numeric}'
      aria-hidden="true"></i>
      <span style="padding-left: 3px;">Must Contain at least one numeric character (0-9)</span>
    </li>
    <li *ngIf="passwordPolicyData.special_chars === 1">
      <i class="fa "
      [ngClass]='{"text-red fa-times-circle-o": !passwordPolicyData.is_special_chars, "text-green fa-check-circle-o": passwordPolicyData.is_special_chars}'
      aria-hidden="true"></i>
      <span style="padding-left: 3px;">Must Contain at least one of the following special characters (! @ # $ & *)</span>
    </li>
  </ul>
</ng-template>
