import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';

import { SharedModule } from 'src/app/modules/shared/shared.module';

import { TokenInterceptor } from './interceptors/token.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { ErrorHandlerInterceptor } from './interceptors/error-handler.interceptor';



import { TermsComponent } from './screens/terms/terms.component';
import { AppComponent } from './app.component';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';

import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import { RppPatientListComponent } from './screens/rpp-patient-list/rpp-patient-list.component';
import { RppPatientAllocateComponent } from './screens/rpp-patient-allocate/rpp-patient-allocate.component';
import { RppRenewListComponent } from './screens/rpp-renew-list/rpp-renew-list.component';
import { RppVieweListComponent,  } from './screens/rpp-view-list/rpp-view-list.component';
import { ReportComponent } from './screens/report/report.component';
import { EarlyWarningFormComponent } from './screens/early-warning/early-warning-form/early-warning-form.component';
import { AddEarlyWarningComponent } from './screens/early-warning/add-early-warning/add-early-warning.component';
import { SuggestionsComponent } from './screens/suggestions/suggestions.component';
import { TeamHierarchyComponent } from './screens/team-hierarchy/team-hierarchy.component';
import { OrganizationChartModule } from 'primeng/organizationchart';
// import { RequestComponent } from './screens/request/request.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({ 
  declarations: [
    AppComponent,
    TermsComponent,
    RppPatientListComponent,
    RppPatientAllocateComponent,
    RppRenewListComponent,
    RppVieweListComponent,
    SuggestionsComponent,
    // RequestComponent,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TabViewModule,
    DropdownModule,
    FullCalendarModule,
    OrganizationChartModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      closeButton: true,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
