<form class="form-inline my-2 my-lg-0 ml-auto form--search"
#sF="ngForm"
(ngSubmit)="onSubmit(q, sF)"
novalidate

>
  <div class="position-relative w-100">
    <input class="form-control  rounded-pill form-control-sm" type="search"
    placeholder="Search Reports"
    aria-label="Search"
    autocomplete="off"
    #searchInput
    [ngClass]="{'invalid': (sfSubmitted && !q)}"
    [ngModelOptions]="{standalone:
      true}"
    [(ngModel)]="q"
    (change)="dataChange(q, sF)"
    aria-label="Search">
    <label (click)="onSubmit(q, sF)" for="submit_search" class="position-absolute l-icon-search">
      <span class="icon-search" style="cursor: pointer;"></span>
    </label>
    <input type="hidden" id="submit_search">
  </div>
</form>
