<!-- START : Page  -->
<form [formGroup]="ai_suggestion" >
<div class="box">
    <div class="box-header pb-3 pt-3">
        <h4 class="box-title">
            <span class="">
                Suggestions</span>
        </h4>
    </div>
    <!-- User statistics  -->

    <div class="box-body">
        <div class="row">
            <div class="col-lg-8"> <textarea rows="2" cols="30" pInputTextarea [autoResize]="true" class="me-3" formControlName="search"></textarea></div>
       <div class="col-lg-4 col-md-4 col-sm-12">
        <div class="mb-3 ">
           
            <button
                    class="btn btn-primary mt-2" type="button" (click)="submit()"><i
                        class="pi pi-search"></i> Generate Response</button>
        </div>

       </div>    
       
        </div>
        <div class="row mt-5 pt-5">
            <div class="col-lg-12">
                <textarea rows="2" cols="30" pInputTextarea formControlName="search_result" [autoResize]="true" class="me-3"></textarea>
            </div>
        </div>
        <!--  -->

        <!-- <div class="row no-gutters grid-table-height">
            <div class="col">
                <app-commongrid #dataGrid [colModel]='cols' gridId='commonGrid' sField='updatedDate' sOrder='desc'
                    selectType='none' [localData]="[]" idColumn='userGrid' [rowsPerPage]='50' paginationpos='bottom'
             >
                    <ng-template #actionTemplate let-gridData='griddata'>

                  


                    </ng-template>
             
                </app-commongrid>
            

            </div>

        </div> -->
    </div>



</div>
</form>