import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cvcid'
})
export class CvcidPipe implements PipeTransform {

  transform(session_site_id: any, prefix: string): string {
    let cvcId = '';
    if (session_site_id) {
      cvcId = prefix +  (''+session_site_id).padStart(6, '0');
    }
    return cvcId;
  }

}
