import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../modules/shared/services/loader.service';
import { environment } from 'src/environments/environment';

const noLoaderApis = [
    `${environment.admin_prefix_direct_url}/user/import_users`,
    `${environment.admin_prefix_direct_url}/location/import_locations`,
    `${environment.admin_prefix_direct_url}/ccp/import_ccp`,
    `${environment.admin_prefix_direct_url}/beneficiary/import_beneficiary`,
    `${environment.admin_prefix_direct_url}/sessionSites/import_session_sites`,
    `${environment.admin_prefix_direct_url}/beneficiary/import_beneficiary_template`
];
const loaderRequireGetApi = [
    `${environment.admin_prefix_direct_url}/location/getLocationFacilitydataGrid/false`,
    `${environment.admin_prefix_url}/location/getLocationFacilitydataGrid/false`,
    `${environment.admin_prefix_url}/user/getUserdataGrid/false`,
    `${environment.admin_prefix_direct_url}/user/getUserdataGrid/false`,
    `${environment.admin_prefix_direct_url}/sessionSites/getSessionSitesDetails/false`,
    `${environment.admin_prefix_url}/sessionSites/getSessionSitesDetails/false`,
    `${environment.session_prefix_url}/bulkSesssion/getSessionDetails/`,
    `${environment.session_prefix_url}/bulkSesssion/getSessionGridData/false`,
    `${environment.session_prefix_direct_url}/bulkSesssion/getSessionGridData/false`,
    `${environment.admin_prefix_url}/doctor_roster/getSlotsByDate`
]

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];

    constructor(private loaderService: LoaderService) { }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        // console.log('this.requests.length', this.requests.length);
        this.loaderService.isLoading.next(this.requests.length > 0);
    }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (loaderRequireGetApi.includes(req.url) || (loaderRequireGetApi.indexOf(req.url) != -1) || (req.url.indexOf(loaderRequireGetApi[9]) != -1)) { } else {if (req.method === 'GET') { return next.handle(req); }}        if (noLoaderApis.includes(req.url)) { return next.handle(req); }
        this.requests.push(req);
        this.loaderService.isLoading.next(true);
        return Observable.create(observer => {
            const subscription = next.handle(req)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.removeRequest(req);
                            observer.next(event);
                        }
                    },
                    err => {
                        this.removeRequest(req);
                        observer.error(err);
                    },
                    () => {
                        this.removeRequest(req);
                        observer.complete();
                    });
            // remove request from queue when cancelled
            return () => {
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }
}
