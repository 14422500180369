import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indentStatus'
})
export class IndentStatusPipe implements PipeTransform {

  transform(value: Number) {
    let status = ''
    switch(value) {
      case 0:
        status = 'Indented';
        break;
      case 1:
        status = 'Paid';
        break;
      case 2:
        status = 'Cancelled';
        break;
      case 3:
        status = 'Confirmed';
        break;
      case 4:
        status = 'Dispatched';
        break;
      case 5:
        status = 'Closed';
        break;
      case 6:
        status = 'Returned';
        break;
      case 7:
        status = 'Rejected';
        break;
      case 8:
        status = 'Partial Dispatch';
        break;
      case 9:
        status = 'Dispatch to consignee';
        break;
      case 10:
        status = 'Received at consignee';
        break;
      case 11:
        status = 'Dispatch to DIO';
        break;
      case 12:
        status = 'Received at DIO';
        break;
      case 13:
        status = 'Dispatch to PCVC';
        break;
      case 14:
        status = 'Returned by consignee';
        break;
      case 15:
        status = 'Returned by DIO';
        break;
      case 16:
        status = 'Returned by PCVC';
        break;
      case 17:
        status = 'Returned by PCVC';
        break;
      default:
        status = 'Indented';
        break;
    }
    return status;
  }

}
