<div *ngIf="stateVisible" class="form-group col-md-4 ">
  <label for="">{{'State'}}{{sRequired ? '*' : '' }}</label>
  <mat-select
    [required]="sRequired" name="state" #state="ngModel" [(ngModel)]="model.state"
    (selectionChange)="stateChange($event.value)" [disabled]="isDisable || stateDisable"
    class="form-control form-control-sm  selectpicker">
    <mat-option *ngFor="let item of stateList | async" [value]="item.state_id">
    {{item.name}}</mat-option>
  </mat-select>
</div>
<div *ngIf="districtVisible" class="form-group col-md-4">
  <label for="">{{'District'}}{{dRequired ? '*' : '' }}</label>
  <mat-select [required]="dRequired" name="district" #district="ngModel" [(ngModel)]="model.district"
    (selectionChange)="districtChange($event.value)" [disabled]="isDisable || districtDisable"
    class="form-control form-control-sm selectpicker">
    <mat-option *ngFor="let item of districtList | async" [value]="item.district_id">
    {{item.name}}</mat-option>
  </mat-select>
</div>
<div *ngIf="blockVisible" class="form-group col-md-4">
  <label for="">{{'Block'}}{{bRequired ? '*' : '' }}</label>
  <mat-select  [required]="bRequired" name="block" #block="ngModel" [(ngModel)]="model.block"
    (selectionChange)="blockChange($event.value)" [disabled]="isDisable || blockDisable"
    class="form-control form-control-sm selectpicker">
    <mat-option *ngFor="let item of blockList | async" [value]="item.block_id">
    {{item.name}}</mat-option>
  </mat-select>
</div>
