<!-- START : Page  -->
<div class="row">
  <div class="col inner-content">
      <div class="page__card position-relative">
        <!--  -->
        <div class="container-fluid px-lg-0">
          <div class="row">
            <div class="col">
              <h1 class="page__title">
                <span class="">Terms</span>
              </h1>
            </div>
          </div>
          <!-- -->
          <div class="row">
            <div class="col-12">
              <div class="terms pl-2">
                <app-cms contentId="terms"></app-cms>
              </div>
            </div>
          </div>
          <!-- -->
        </div>
      </div>
  </div>
</div>
<!-- END : Page  -->
