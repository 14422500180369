import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, ViewContainerRef, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { GenericPopupService } from 'src/app/modules/generic-popup/services/generic-popup.service';
import { DataService } from '../../services';
import { Subscription } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';
import { ModalType } from 'src/app/modules/generic-popup/model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  @ViewChild('modalTemplate', { static: true }) modalTemplate: TemplateRef<any>;
  private typeSubscription: Subscription;
  userDetails;

  constructor(private viewContainerRef: ViewContainerRef,
              private genericPopupService: GenericPopupService,
              public dataService: DataService) { }

  ngOnInit(): void {
    this.typeSubscription = this.genericPopupService.type
      .subscribe((mod) => {
        if (mod && mod.type === ModalType.SECONDARY_1) {
        this.genericPopupService.setModalPortal(new TemplatePortal<any>(this.modalTemplate, this.viewContainerRef));
        this.userDetails = this.dataService.userDetails;
        }
      });
  }

  closePopup(): void {
    this.genericPopupService.close();
  }

  ngOnDestroy() {
    try{
      this.typeSubscription.unsubscribe();
    }catch(e){}
  }

}
