import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'departmentName'
})
export class DepartmentNamePipe implements PipeTransform {

  transform(department_id: number, department_list: any[]): string {
    let department_name = null;
    let foundObj = department_list.find(obj => obj.dept_id == department_id);
    if (foundObj) {
      department_name = foundObj.dept_name;
    }
    return department_name;
  }

}
