import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  appVersionObservable$: Observable<any>;
  configObservables = new Object();

  constructor(private http: HttpClient) { }

  getVersionDetails(): Observable<any> {
    if (!this.appVersionObservable$) {

      this.appVersionObservable$ = this.http.get(
                                      `${environment.admin_prefix_url}/mobile/appVersion`).pipe(
                                      map(res => res),
                                      publishReplay(1),
                                      refCount()
                                   );
    }
    return this.appVersionObservable$;
  }

  getConfig(key): Observable<any> {
    if (!this.configObservables.hasOwnProperty(key)) {
      this.configObservables[key] = this.http.get<any>(
                                    `${environment.user_prefix_url}/getConfig/${key}`
                                    ).pipe(
                                      map(res => res),
                                      publishReplay(1),
                                      refCount()
                                    );
    }
    return this.configObservables[key];
  }
}
