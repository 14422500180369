import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RppViewListService {

  constructor(
    private http: HttpClient
  ) { }

  getPatientRPPViewGridDetails(payload){
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/getAllPatientRppGrid`,payload)
  }

  exportCSV(){
    return this.http.get(`${environment.patient_registration_prefix_url}/rpp/exportAllPatientRppGrid`, { responseType: 'blob'})
  }
}
