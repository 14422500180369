<ng-template #modalTemplate1>
    <div class="profile-block bg-white">
        <div class="modal-header pt-2 pb-2">
            <h4 class="modal-title">
                Hierarchy
            </h4>
            <button (click)="closePopup()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

    
        
        <div class="content-section implementation">
            <div>
            
                <!-- <p-organizationChart #orgChart [value]="hierarchy"
                                     selectionMode="single" [(selection)]="selectedNode" styleClass="company"
                                     (onNodeExpand)="onSelectedNodeExpand($event)">
                    <ng-template let-node pTemplate="person">
                        <div class="node-header">{{node.label}}</div>
                        <div class="node-content">
                            <img [src]="node.data.profile_pic" width="32">
                            <div>{{node.data.name}}</div>
                            <span class="designation-span">{{node.data.designation}}</span>
                        </div>
                    </ng-template>
                    <ng-template let-node pTemplate="department">
                        {{node.label}}
                    </ng-template>
                </p-organizationChart> -->
            </div>
          </div>
          
    </div>
</ng-template>


<!-- <p-button (click)="gfg()" label="Click Here"></p-button>
<p-dialog header="Team" position="top" header="Team" [(visible)]="geeks">

    <p-tabView>
        <p-tabPanel header="Hierarchy">
           
                <div class="card flex justify-content-center">
                    <p-organizationChart [value]="data"></p-organizationChart>
                </div>

        </p-tabPanel>
        <p-tabPanel header="Team">
            <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim
                ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.
            </p>
        </p-tabPanel>
        <p-tabPanel header="Unit">
            <p>
                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa
                qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
            </p>
        </p-tabPanel>
        <p-tabPanel header="Report">
            <p>
                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa
                qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
            </p>
        </p-tabPanel>
    </p-tabView>
</p-dialog> -->