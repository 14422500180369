import { IconsModule } from './icons.module';
import { AadharNumberDirective } from './directives/aadhar-number.directive';
import { AadhaarNumberValidationDirective } from './directives/aadhaar-validation.directive';
import { StockTransferStatusPipe } from './pipes/stock-transfer-status.pipe';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import { FourNumberDirective } from './directives/four-number.directive';
import { AgeNumberDirective } from './directives/age-number.directive';
import { ReferenceNumberDirective } from './directives/referenceid-number.directive';
import { OtpNumberDirective } from './directives/otp-number.directive';
import { ChatBotComponent } from './components/chat-bot/chat-bot.component';
import { NgModule } from '@angular/core';
import { BadgeModule } from 'primeng/badge';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {DialogModule} from 'primeng/dialog'
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatTreeModule } from '@angular/material/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { GenericPopupModule } from './../generic-popup/generic-popup.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {RatingModule} from 'primeng/rating';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimeagoModule } from 'ngx-timeago';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxSpinnerModule } from 'ngx-spinner';

import { MinDirective } from './directives/min.directive';
import { MaxDirective } from './directives/max.directive';
import { RangeLengthDirective } from './directives/range-length.directive';
import { RangeDirective } from './directives/range.directive';
import { InputCharDirective } from './directives/input-char.directive';
import { MobileNumberDirective } from './directives/mobile-number.directive';
import { ZeroNotallowedDirective } from './directives/zero-notallowed.directive';
import { IntegerInputDirective } from './directives/input-integer.directive';
import { InputTrimDirective } from './directives/input-trim.directive';
import { OnlynumberDirective } from './directives/numbersOnly.directive';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CmsComponent } from './components/cms/cms.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ChangeMobilenumberComponent } from './components/profile/change-mobilenumber/change-mobilenumber.component';
import { ChangePasswordComponent } from './components/profile/change-password/change-password.component';
import { ProfileFormComponent } from './components/profile/profile-form/profile-form.component';
import { SearchComponent } from './components/search/search.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { SelectLocationComponent } from './components';
import { CommonLocationFieldsComponent } from './components/common-location-fields/common-location-fields.component';
import { LoaderComponent } from './components/loader/loader.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import { NegativezeroNotallowedDirective } from './directives/negativezero-notallowed.directive';
import {MatTableModule} from '@angular/material/table';
import { TitleCaseCustomPipe } from './pipes/title-case-custom.pipe';
import { NumberFormatPipe } from '../shared/pipes/number-format.pipe';
import {environment} from '../../../environments/environment';
import { CvcidPipe } from './pipes/cvcid.pipe';
import { IndentStatusPipe } from './pipes/indent-status.pipe';
import { ManufacturerConsigneeStockTransfer } from './pipes/manufacturer-consignee-stock-transfer-status.pipe';
import { ConsigneeCVCStockTransfer } from './pipes/consignee-cvc-stock-transfer-status.pipe';
import { AgeRangeDirective } from './directives/age-range.directive';
import { TableModule } from 'primeng/table';
import { CommongridComponent } from '../commongrid/commongrid.component';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import {ButtonModule} from 'primeng/button';
import {MenuModule} from 'primeng/menu';
import { RippleModule } from 'primeng/ripple';
import {RadioButtonModule} from 'primeng/radiobutton';
import {CardModule} from 'primeng/card';
import { TabViewModule } from 'primeng/tabview';
import { DepartmentNamePipe } from './pipes/department-name.pipe';
import {CalendarModule} from 'primeng/calendar';
import {AccordionModule} from 'primeng/accordion';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {ChartModule} from 'primeng/chart';
import { InputNumberModule } from "primeng/inputnumber";
import {MatStepperModule} from '@angular/material/stepper';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);


import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { SignatureComponent } from './components/profile/signature/signature.component';
import { CountUpModule } from 'ngx-countup'
import { InputTextareaModule } from "primeng/inputtextarea";
import { InputTextModule } from "primeng/inputtext";

import {OrganizationChartModule} from 'primeng/organizationchart';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe'
import { HierarchyComponent } from './components/profile/hierarchy/hierarchy.component';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
  
} from '@angular-material-components/datetime-picker';
import { FileUploadModule } from 'primeng/fileupload';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const exportComponents = [
  HeaderComponent,
  FooterComponent,
  CmsComponent,
  SideBarComponent,
  SearchComponent,
  SelectLocationComponent,
  CommonLocationFieldsComponent,
  ProfileComponent,
  LoaderComponent,
  ChatBotComponent,
  CommongridComponent,
];

const exportDirectives = [
  InputCharDirective,
  RangeDirective,
  RangeLengthDirective,
  MaxDirective,
  MinDirective,
  MobileNumberDirective,
  ZeroNotallowedDirective,
  InputTrimDirective,
  OnlynumberDirective,
  IntegerInputDirective,
  NegativezeroNotallowedDirective,
  OtpNumberDirective,
  ReferenceNumberDirective,
  AgeNumberDirective,
  FourNumberDirective,
  AadhaarNumberValidationDirective,
  AadharNumberDirective,
  AgeRangeDirective
];

const exportPipes = [
  ShortNumberPipe,
  TitleCaseCustomPipe,
  NumberFormatPipe,
  CvcidPipe,
  IndentStatusPipe,
  StockTransferStatusPipe,
  ManufacturerConsigneeStockTransfer,
  ConsigneeCVCStockTransfer,
  DepartmentNamePipe,
  CustomCurrencyPipe
];


const exportModules = [
  CommonModule,ChartModule,InputNumberModule,RatingModule, NgxIntlTelInputModule,
  RouterModule, MatStepperModule,BadgeModule,
  FormsModule,TabViewModule,
  ReactiveFormsModule,
  GenericPopupModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatSelectModule,
  MatExpansionModule,
  MatChipsModule,
  MatCardModule,
  MatTreeModule,
  MatButtonModule,
  MatCheckboxModule,
  MatRadioModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTableModule,
  DropdownModule,
  MultiSelectModule,
  MatMenuModule,
  ButtonModule,
  MenuModule,
  RippleModule,
  RadioButtonModule,
  CardModule,
  TableModule,
  CalendarModule,
  AccordionModule,
  IconsModule,
  DynamicDialogModule,
  FullCalendarModule,
  MatFormFieldModule,
  MatInputModule,
  MatMomentDateModule,  
  MatDatepickerModule,
  NgxMatSelectSearchModule,
  DialogModule,
  ClipboardModule,
  CountUpModule,InputTextareaModule,InputTextModule,
  MatSlideToggleModule,
  NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule
];
@NgModule({
  declarations: [
    ...exportComponents,
    ...exportDirectives,
    ...exportPipes,
    ChangeMobilenumberComponent,
    ChangePasswordComponent,
    ProfileFormComponent,
    AgeRangeDirective,
    SignatureComponent,
    HierarchyComponent
  ],
  imports: [
    ...exportModules,
    ToastModule,
    NgxSpinnerModule,
    FileUploadModule,
    OrganizationChartModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    TimeagoModule.forRoot(),
    CarouselModule.forRoot(),
    PopoverModule.forRoot()
  ],
  exports: [
    ...exportComponents,
    ...exportDirectives,
    ...exportPipes,
    ...exportModules,
    CarouselModule,
    BsDropdownModule,
    TooltipModule,
    PopoverModule,
    TimeagoModule
   
  ],
  providers: [
    MessageService,
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class SharedModule {
  constructor(private encDecService: EncDecService) {}
}
