import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { DataService, ProfileService } from '../../../services';
import { TreeNode } from 'primeng/api';
import { OrganizationChart } from 'primeng/organizationchart';
import { Subscription } from 'rxjs';
import { GenericPopupService } from 'src/app/modules/generic-popup/services/generic-popup.service';
import { ModalType } from 'src/app/modules/generic-popup/model';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-hierarchy',
  templateUrl: './hierarchy.component.html',
  styleUrls: ['./hierarchy.component.scss']
})
export class HierarchyComponent implements OnInit {
  hierarchy: any;
  selectedNode: TreeNode;
  userDetails
  geeks: boolean;
  // @ViewChild('orgChart') orgChart: OrganizationChart

  @ViewChild('modalTemplate1', { static: true }) modalTemplate1: TemplateRef<any>;
  private typeSubscription: Subscription;
  constructor(
     private userService: ProfileService,
     private dataService: DataService,
     private viewContainerRef: ViewContainerRef,
     private genericPopupService: GenericPopupService,
  ) { }

  async ngOnInit() {
    
    this.typeSubscription = this.genericPopupService.type
      .subscribe((mod) => {
        if (mod && mod.type === ModalType.HIERARCHY) {
        this.genericPopupService.setModalPortal(new TemplatePortal<any>(this.modalTemplate1, this.viewContainerRef));
        this.userDetails = this.dataService.userDetails;
        }
      });

    let user_id = this.dataService.userDetails.user_id;

    await this.getHierarchy(user_id);
  }
//   gfg() {
//     this.geeks = true;
//   }
//   data: TreeNode[] = [
//     {
//         label: 'F.C Barcelona',
//         expanded: true,
//         children: [
//             {
//                 label: 'Argentina',
//                 expanded: true,
//                 children: [
//                     {
//                         label: 'Argentina'
//                     },
//                     {
//                         label: 'France'
//                     }
//                 ]
//             },
//             {
//                 label: 'France',
//                 expanded: true,
//                 children: [
//                     {
//                         label: 'France'
//                     },
//                     {
//                         label: 'Morocco'
//                     }
//                 ]
//             }
//         ]
//     }
// ];
  ngOnDestroy() {
    try{
      this.typeSubscription.unsubscribe();
    }catch(e){}
  }

  async getHierarchy(user_id) {
    let data: any = await this.userService.getUserHierarchy(user_id).toPromise();    
    await this.setProfilePicToHierarchy(data.hierarchy);
    this.hierarchy = [data.hierarchy];
    this.selectedNode = data.selected_node;
    //this.orgChart._selection = this?.selectedNode;
    setTimeout(() => {
      let divElementColl: HTMLCollectionOf<Element> = document.getElementsByClassName('p-organizationchart-selectable-node');
      //let divElement = divElementColl[0].classList.add('p-highlight');

    }, 100);
  }

  closePopup(): void {
    this.genericPopupService.close();
  }

  async setProfilePicToHierarchy(data) {
   // this.recursiveFunction(data);
  }

  
  // onSelectedNodeExpand(event: {originalEvent:PointerEvent, node: TreeNode}) {
    
  //   console.log(event.node.data);

  // }

  // private recursiveFunction(data: any) {
  //   if (data.children && data.children.length > 0) {
  //     _.each(data.children, async (child) => {
  //       if (child.children && child.children.length > 0) {
  //         this.recursiveFunction(child);
  //       }
  //     });
  //   }
  // }

}
