<!-- START : Page  -->
<div class="box">
    <div class="box-header pb-3 pt-3">
        <h4 class="box-title">
            <span class="">{{dataService.checkPermission('Patient Registration') === 'READ' ? 'RPP Renew Patient List' :
                'RPP Renew Patient List'}}</span>
        </h4>
    </div>
    <!-- User statistics  -->
    <div class="box-body">
        <div class="row">
            <div class="box-body">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12"></div>
                    <div class="col-md-8 ml-auto text-secondary">
                        <div class="input-group mb-3 search-mob pull-right">
                            <input type="text" class="form-control" name="contact" maxlength="12"
                                (keydown.enter)="searchWithPhoneNumber()"
                                [(ngModel)]="contactNumber" placeholder="Name/Mobile No.">
                            <div class="input-group-append">
                                <button
                                    [ngClass]="{'btn-dark' : disableSearch, 'btn-primary': !disableSearch}"
                                    class="btn btn-sm" type="button" (click)="searchWithPhoneNumber()"><i
                                        class="pi pi-search"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  -->
                <div class="row">
                    <div class="col">
                        <div class="row no-gutters grid-table-height">
                            <div>
                                <strong>{{noteinfo}}</strong>
                            </div>
                            <div class="col">
                                <app-commongrid #dataGrid [colModel]='cols' gridId='commonGrid' sField='updatedDate'
                                    sOrder='desc' selectType='none' [localData]="[]" idColumn='userGrid'
                                    [rowsPerPage]='50' paginationpos='bottom'
                                    (onCustomPageAndFilterEvent)="onPageChangeEvent($event)"
                                    (onCustomFilterEvent)="onCustomFilter($event)">
                                    <ng-template #actionTemplate let-gridData='griddata'>
                                        <!-- *ngIf="dataService.addeditPermission('Patient List') && (gridData.status == 'Paid'||gridData.status == 'Patient Details Completed'||gridData.status == 'Assessments & Summary Completed'||gridData.status == 'Form 5 Completed'||gridData.status == 'Diagnosis Completed' || gridData.status == 'Treatment Form Completed'|| gridData.status == 'Session Completed')" -->

                                        <button *ngIf="dataService.addeditPermission('RPP Patient Assignment') && (gridData.status == 'Paid') || 
                                 (gridData.status == 'Pay Later') ||
                                 (gridData.status == 'Assigned') || (gridData.status == 'Expired')"
                                            (click)="editPatient(gridData)" class="butonclover" title="Edit">
                                            <span class="actionicons iconscls text-warning"><i
                                                    class="ti ti-pencil"></i></span>
                                        </button>


                                    </ng-template>
                                    <ng-template #statuTemplate let-gridData='griddata'>

                                        <ng-container *ngIf="gridData.status == 'Expired'
                                        ">
                                            <span class="badge badge-danger me-3">Expired</span>
                                            <a *ngIf="showExpiredbutton" 
                                            href="#" class="" data-toggle="tooltip"
                                                (click)="redirectregisterpage(gridData)" data-placement="top"
                                                title="Renew"><i class="pi pi-refresh"></i></a>
                                        </ng-container>

                                    </ng-template>
                                    <ng-template #payTemplate let-gridData='griddata'>
                                        <ng-container *ngIf="gridData.payment_type_name == 'Pay Later'">
                                            <span class="badge badge-danger">Pay Later</span>
                                            <a href="#" class="me-3" data-toggle="tooltip"
                                                (click)="redirectregisterpage(gridData)" data-placement="top"
                                                title="Update Payment"><img
                                                    src="assets/images/hospital-icon/paylater.svg" height="34px"></a>
                                        </ng-container>
                                        <ng-container *ngIf="gridData.payment_type_name == 'Cash'">
                                            <span class="badge badge-success">Cash</span>

                                        </ng-container>
                                        <ng-container *ngIf="gridData.payment_type_name == 'Online'">
                                            <span class="badge badge-success">Online</span>
                                        </ng-container>
                                    </ng-template>
                                </app-commongrid>
                                <!--  -->

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- END : Page  -->