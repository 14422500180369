import { Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Colmodel, GridFIlterType } from 'src/app/modules/commongrid/colmodel.model';
import { CommongridComponent } from 'src/app/modules/commongrid/commongrid.component';
import { PatientRegisterService } from '../patient-registration/services/patient-register.service';
import { RppRenewListService } from '../rpp-renew-list/rpp-renew-list.service';
import { RppViewListService } from './rpp-view-list.service';



@Component({
  selector: 'app-rpp-view-list',
  templateUrl: './rpp-view-list.component.html',
  styleUrls: ['./rpp-view-list.component.scss']
})
export class RppVieweListComponent implements OnInit{
  cols: Colmodel[] = [];
  currentPage = 1;
  disableSearch = true;
  public contactNumber: any = '';

  @ViewChild(CommongridComponent) dataGrid: CommongridComponent;
  @ViewChild('payTemplate', {static: true}) payTemplate;
  @ViewChild('actionTemplate', {static: true}) actionTemplate;
  @ViewChild('statuTemplate', {static: true}) statuTemplate;

  
  constructor(public viewService : RppViewListService,private patientRegisterService: PatientRegisterService,
    public route: Router,){

  }


    ngOnInit(): void {
      this.cols = [
        new Colmodel('serial_num', 'Sr No', false, true, false),
        new Colmodel('patient_ref_id', 'Patient Id', false, true, false, { filtertype: GridFIlterType.INPUT }),
        new Colmodel('patient_id', 'Id', false, true, true, {filtertype: GridFIlterType.INPUT}),
        new Colmodel('patient_name', 'Patient Name', false, true, false, {filtertype: GridFIlterType.INPUT}),
        new Colmodel('mobile_number', 'Mobile Number', false, true, false, {filtertype: GridFIlterType.INPUT}),
        new Colmodel('package_name', 'Plan Name', false, true, false, {filtertype: GridFIlterType.INPUT}),
        new Colmodel('enrollment_date', 'Enrollment Date', false, true, false, {filtertype: GridFIlterType.INPUT}),
        new Colmodel('due_date', 'Due Date', false, true, false, {filtertype: GridFIlterType.INPUT}),
        new Colmodel('registration_date', 'Date of Registration', false, true, false, {filtertype: GridFIlterType.INPUT}),
        new Colmodel('psychiatrist', 'Psychiatrist', false, true, false, {filtertype: GridFIlterType.INPUT}),
        new Colmodel('psychologist', 'Psychologist', false, true, false, {filtertype: GridFIlterType.INPUT}),
        new Colmodel('feedback_assignment', 'Feedback Counsellor', false, true, false, {filtertype: GridFIlterType.INPUT}),
        new Colmodel('activity_assignment', 'Actvity Counsellor', false, true, false, {filtertype: GridFIlterType.INPUT}),
        new Colmodel('pd_assignment', 'Form 5 (Diagnosis Completed)', false, true, false, {filtertype: GridFIlterType.INPUT}),
        // new Colmodel('service_details', 'Service Assigned', false, true, false, {filtertype: GridFIlterType.INPUT}),
        // new Colmodel('action', 'Actions', false, false, this.disableColumn, {filtertype: GridFIlterType.NONE, formatterTemplateRef: this.actionTemplate}),
        new Colmodel('status', 'Status', false, true, false, {filtertype: GridFIlterType.INPUT}),
        new Colmodel('assesment_status', 'Assessment Status', false, true, false, { filtertype: GridFIlterType.INPUT }),
        new Colmodel('wearable_status', 'Wearable Status', false, true, false, { filtertype: GridFIlterType.INPUT }),
        new Colmodel('wearable_last_comment', 'Wearable Last Comment', false, true, false, { filtertype: GridFIlterType.INPUT }),
        new Colmodel('isConsentFormFilled', 'Consent Form Filled', false, true, false, { filtertype: GridFIlterType.INPUT }),
        new Colmodel('date_consent_created', 'Consent Date', false, true, false, { filtertype: GridFIlterType.INPUT }),
        new Colmodel('comments', 'Comments', false, true, false, { filtertype: GridFIlterType.INPUT }),
      ];
    this.getPatientGridData()
    }

    getPatientGridData() {
      const tempPayload = {
        page_size: this.dataGrid && this.dataGrid.rows ? this.dataGrid.rows : 50,
        current_page: this.currentPage,

      };
      this.viewService
        .getPatientRPPViewGridDetails(tempPayload)
        .subscribe((res: any) => {
          this.dataGrid.data = res.data;
          this.dataGrid.totalRecords = res.count;
        });
    }

    keyPressNumbers(event){
      var charCode = (event.which) ? event.which : event.keyCode;
      this.disableSearch =  this.contactNumber.length >= 9 ? false : true;
      // Only Numbers 0-9
      if ((charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
      } else {
        return true;
      }
    }
  
    onFocusOutEvent(event){
      this.disableSearch =  this.contactNumber.length >= 10 ? false : true;
      if (this.disableSearch) {
        this.getPatientGridData()
      }
    } 
    searchWithPhoneNumber(){
      if(this.contactNumber == ""){
        this.getPatientGridData()
      }
      let payload =
      {
        search_keyword : this.contactNumber,
      }
      this.patientRegisterService.findbyMobileNumberRppViewList(payload).subscribe((res: any)=>{
        this.dataGrid.data = res.data;
        this.dataGrid.totalRecords = res.count;
      })
    }

    exportCSV() {
      let filename = 'RPP List'
      let payload = {
      }
  
      this.viewService.exportCSV().subscribe((res: any) => {
        const blob: any = new Blob([res], { type: 'text/csv' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
    }
    onCustomFilter(event) {
      this.dataGrid.totalRecords = event.data.length;
    }
    OnChanges(){

    }
    onPageChangeEvent(event) {
      this.currentPage = event.first == 0 ? 1 : (event.first/event.rows) + 1;
      this.dataGrid.rows = event.rows;
      // const payload = {filters: event.filters};
      const payload = {limit: true};
      this.getPatientGridData();
    }

    onCustomrowSelect(event) {
      const navigationExtras: NavigationExtras = {
        state: { editData: JSON.stringify(event) },
      };
      this.route.navigate([`/rpp-list/patient-dashboard/${event.patient_id}`], navigationExtras)
    }
}