import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChatBotService } from '../../services/chat-bot.service';

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss']
})
export class ChatBotComponent implements OnInit {
  public document: any;
  public inputMessage = '';
  @ViewChild('personName') personName: ElementRef | undefined;
  @ViewChild('sendMessage') sendMessage: ElementRef | undefined;
  @ViewChild('endChat') endChat: ElementRef | undefined;
  @ViewChild('chatBotIcon') chatBotIcon: ElementRef | undefined;
  public showHideWriteMessage = false;
  constructor(private chatBotService: ChatBotService) {}

  ngOnInit(): void {
    // this.close_chat();
  }

  toggleClass = false;
  ngAfterViewInit(): void {
    // this.close_chat();
    if (this.sendMessage?.nativeElement) {
      const keyDownEventOnInput = fromEvent(this.sendMessage?.nativeElement, 'keydown');
      keyDownEventOnInput.pipe(
        map((event: any) => {
          if (event.keyCode === 13) {
            this.send_message();
            this.inputMessage = '';
            this.showHideWriteMessage = false;
          }
        })
      ).subscribe();

      const keyPressEventOnInput = fromEvent(this.sendMessage?.nativeElement, 'keypress');
      keyPressEventOnInput.pipe(
        map((event: any) => {
          if (event.keyCode !== 13) {
            this.showHideWriteMessage = true;
            this.updateScroll();
          }
        })
      ).subscribe();

      const blurEventOnInput = fromEvent(this.sendMessage?.nativeElement, 'blur');
      blurEventOnInput.pipe(
        map((event: any) => {
          this.showHideWriteMessage = false;
        })
      ).subscribe();
    }

    if (this.personName?.nativeElement) {
      const keyDownEventOnInput = fromEvent(this.personName?.nativeElement, 'keydown');
      keyDownEventOnInput.pipe(
        map((event: any) => {
          if (event.keyCode === 13) {
            this.validateParam();
          }
        })
      ).subscribe();
    }

    if (this.endChat?.nativeElement) {
      const clickEvent = fromEvent(this.endChat?.nativeElement, 'click');
      clickEvent.pipe(
        map((event: any) => {
          this.document = window.document;
          this.document.getElementsByClassName('chat-body')[0].classList.add('hide');
          this.document.getElementsByClassName('chat-input')[0].classList.add('hide');
          this.document.getElementsByClassName('chat-session-end')[0].classList.remove('hide');
          this.document.getElementsByClassName('chat-header-option')[0].classList.add('hide');
        })
      ).subscribe();
    }

    if (this.chatBotIcon?.nativeElement) {
      const clickEvent = fromEvent(this.chatBotIcon?.nativeElement, 'click');
      clickEvent.pipe(
        map((event: any) => {
          this.document = window.document;
          this.toggleClass = !this.toggleClass;
          if (!this.toggleClass) {
            this.document.getElementsByClassName('feather-message-square')[0].classList.add('animate');
            this.document.getElementsByClassName('chat-screen')[0].classList.add('show-chat');
          } else {
            this.document.getElementsByClassName('feather-message-square')[0].classList.remove('animate');
            this.document.getElementsByClassName('chat-screen')[0].classList.remove('show-chat');
          }
        })
      ).subscribe();
    }
  }

  validateParam(): any{
    this.document = document;
    this.document.getElementsByClassName('chat-mail')[0].classList.add('hide');
    this.document.getElementsByClassName('chat-body')[0].classList.remove('hide');
    this.document.getElementsByClassName('chat-input')[0].classList.remove('hide');
    this.document.getElementsByClassName('chat-header-option')[0].classList.remove('hide');
    var person_name= this.document.getElementById("person_name").value;
    if(person_name.trim()==''){
       person_name.style.color = "red";
       return false;
    }else {
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var day_name = (days[new Date().getDay()]);
        var dt_time = this.formatAMPM(new Date);

        this.document.getElementsByClassName("chat-start").text = day_name+', '+dt_time;
        this.document.getElementById("showP").text = "Hi, "+person_name;
        var chat_mail = this.document.getElementsByClassName("chat-mail");
        var chat_body = this.document.getElementsByClassName("chat-body");
        var chat_input = this.document.getElementsByClassName("chat-input");
        var chat_header_option = this.document.getElementsByClassName("chat-header-option");

        var id_chat_mail = this.document.getElementById("chat_mail");
        var id_chat_body = this.document.getElementById("chat_body");
        var id_chat_input = this.document.getElementById("chat_input");
        // var id_chat_header_option = document.getElementById("chat_header_option");

        id_chat_mail.classList.add("hide");
        id_chat_body.classList.remove("hide");
        id_chat_input.classList.remove("hide");
        // id_chat_header_option.classList.remove("hide");
    }
  }

  formatAMPM(date: Date) {
    let hours = date.getHours();
    let minutes: any = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  send_message(): any {
    this.document = document;
    var msg = this.document.getElementById("send_msg").value.trim();

    var lt = /</g,
        gt = />/g,
        ap = /'/g,
        ic = /"/g;
        msg = msg.toString().replace(lt, "&lt;").replace(gt, "&gt;").replace(ap, "&#39;").replace(ic, "&#34;");
    var specials=/[*|\":<>[\]{}`\\()';@&$#^%]/;
    if(msg==''){
      this.document.getElementById("send_msg").style.borderColor='red';
      return false;
    } else if(specials.test(msg)){
      this.document.getElementById("send_msg").style.borderColor='red';
      return false;
    } else {
      this.document.getElementById("send_msg").value="";
      var newDiv= this.document.createElement('div');
      newDiv.className = 'chat-bubble you';
      newDiv.innerHTML = msg;
      var item = this.document.getElementById('chat_history');
      item.appendChild(newDiv);

      this.chatBotService.sendMessage(msg).subscribe((res: any) => {
        this.displayMessage(res);
      });
    }
    this.updateScroll();
  }

  updateScroll(): any{
    this.document = document;
    var element = this.document.querySelector(".chat-body");
    element.scrollTop = element.scrollHeight;
  }

  displayMessage(response: any) {
    this.document = document;
    var response_json = response;
    var newDiv= document.createElement('div');
    newDiv.className = 'chat-bubble me';
    newDiv.innerHTML = response_json.response;
    var item = this.document.getElementById('chat_history');
    item.appendChild(newDiv);
  }

  close_chat(){
    this.document = document;
    var chat_session_end = this.document.getElementById("chat_session_end");
    var open_chat = this.document.getElementById("open_chat");

    var id_chat_mail = this.document.getElementById("chat_mail");
    var id_chat_body = this.document.getElementById("chat_body");
    var id_chat_input = this.document.getElementById("chat_input");
    var id_chat_header = this.document.getElementById("chat_header");

    chat_session_end.classList.add("hide");

    id_chat_mail.classList.add("hide");
    id_chat_body.classList.add("hide");
    id_chat_input.classList.add("hide");
    id_chat_header.classList.add("hide");
    open_chat.classList.remove("hide");
  }

  open_chat(){
    this.document = document;
    var chat_session_end = this.document.getElementById("chat_session_end");
    var id_chat_mail = this.document.getElementById("chat_mail");
    var id_chat_header = this.document.getElementById("chat_header");
    var id_chat_body = this.document.getElementById("chat_body");
    var id_chat_input = this.document.getElementById("chat_input");
    var open_chat = this.document.getElementById("open_chat");
    var close_chat = this.document.getElementById("close_chat");

    chat_session_end.classList.add("hide");
    id_chat_body.classList.remove("hide");
    id_chat_header.classList.remove("hide");
    id_chat_input.classList.remove("hide");
    open_chat.classList.add("hide");
    close_chat.classList.remove("hide");
  }
}
