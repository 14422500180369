import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ConsCvcStockStatus'
})
export class ConsigneeCVCStockTransfer implements PipeTransform {

  transform(value: Number) {
    let status = ''
    switch(value) {
      case 1:
        status = 'Dispatch To PCVC';
        break;
      case 2:
        status = 'Returned By PCVC';
        break;
      case 3:
        status = 'Closed';
        break;
      case 4:
        status = 'Cancelled';
        break;
    }
    return status;
  }

}
