import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({providedIn: 'root'})
export class ChatBotService {

  constructor(private http: HttpClient) {}

  sendMessage(message: string): Observable<any> {
    const url= `${environment.chatboturl}${message}`;
    return this.http.get<any>(
      url
    );
  }
}
