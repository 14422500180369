import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';

import { ModalModule } from 'ngx-bootstrap/modal';

import { GenericPopupComponent } from './generic-popup.component';

@NgModule({
  declarations: [GenericPopupComponent],
  imports: [
    CommonModule,
    PortalModule,
    ModalModule.forRoot(),
  ],
  exports: [GenericPopupComponent]
})
export class GenericPopupModule { }
