import { WebSocketService } from './../../services/websocket.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { GenericPopupService } from 'src/app/modules/generic-popup/services/generic-popup.service';
import { ModalPosition, ModalType } from 'src/app/modules/generic-popup/model';
import { DataService, ProfileService, UtilsService } from '../../services';

import { AuthService } from 'src/app/screens/auth/services/auth.service';

import { environment } from 'src/environments/environment';
import { EncDecService } from '../../services/encryption-decryption.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit, AfterViewInit {
  isUserLoggedIn = false;
  public languageHead: any;
  userDetails: any;
  profilePic: any;
  isExpanded = true;
  isDistrictlogin;
  admindistrictid;
  sideNavBarMenuItems: {
    menuLabel: string;
    link: string;
    isFunction?: boolean;
    iconClass: string;
    permission: boolean;
  }[] = [];
  havePermission = false;
  constructor(
    private auth: AuthService,
    private genericPopupService: GenericPopupService,
    public dataService: DataService,
    private profileService: ProfileService,
    private websocket: WebSocketService,
    public util: UtilsService,
    private encDecService: EncDecService
  ) {
    this.auth.currentUser.subscribe((r) => (this.isUserLoggedIn = r));
    this.initializeMenuItems();
  }

  initializeMenuItems(): void {
    this.dataService.permissionPresent$.subscribe((res) => {
      if (res) {
        this.sideNavBarMenuItems = [];
        this.menuItemsInSideBar();
      }
    });
  }
  async menuItemsInSideBar() {
    _.each(this.dataService.userDetails.module_json, (menuItem) => {
      this.sideNavBarMenuItems.push(
        {
          menuLabel: menuItem.menu_name,
          link: menuItem.route_url,
          iconClass: menuItem.icon_class,
          permission: menuItem.display_permission == 1
        }
      )
    });
  }

  ngOnInit(): void {
    const body$ = document.getElementsByTagName('body')[0];
    body$.classList.remove('overflow-hidden');
    if (this.auth.currentUserValue) {
      this.userDetails = this.dataService.userDetails;
      // this.profileService.getProfilePic().subscribe();
    }
  }

  logout() {
    if (this.util.getScreenSize() < 991) {
      const styleKey = 'style';
      const displayKey = 'display';
      const sidebarPopup$ = document.getElementsByClassName('sidebar-popup')[0];
      const isVisible =
        sidebarPopup$[styleKey][displayKey] === 'none' ? false : true;
      if (isVisible) {
        this.sidebarToggle();
      }
    }
    this.websocket.disconnect();
    this.auth.logout();
  }

  ngAfterViewInit() {
    if (this.util.getScreenSize() < 991) {
      /* document.addEventListener('DOMContentLoaded', (event) => { */
      const body$ = document.getElementsByTagName('body')[0];
      body$.classList.remove('overflow-hidden');
      const sideNavDiv$ = document.createElement('div');
      sideNavDiv$.className = 'sidenav-overlay';
      body$.appendChild(sideNavDiv$);
      const sidebarDiv$ = document.getElementsByClassName('sidebar')[0];
      const sliderClose$ =
        document.getElementsByClassName('btn-slider-close')[0];
      const sidebarToggle$ =
        sidebarDiv$.getElementsByClassName('sidebar-toggler')[0];
      sidebarToggle$.addEventListener('click', (e) => {
        e.preventDefault();
        this.sidebarToggle();
      });

      /* sliderClose$.addEventListener('click', (e) => {
          e.preventDefault();
          this.sidebarToggle();
        }); */
      /* }); */
    }
  }

  openReports() {
    this.sidebarToggle();
    // window.open(`${environment.v1_url}/service-request?request=reports&key=${JSON.parse(sessionStorage.getItem('userToken'))}`);
  }

  openReportsWithoutToggle() {
    // window.open(`${environment.v1_url}/service-request?request=reports&key=${JSON.parse(sessionStorage.getItem('userToken'))}`);
  }

  openProfile() {
    this.genericPopupService.open(ModalPosition.CENTER, ModalType.SECONDARY_1);
    this.sidebarToggle();
  }

  sidebarToggle() {
    const body$ = document.getElementsByTagName('body')[0];
    const sidebarPopup$ = document.getElementsByClassName('sidebar-popup')[0];
    this.fadeInOutEffect(sidebarPopup$, body$);
  }

  fadeInOutEffect(element, bodyElement?) {
    /* setTimeout(() => { */
    const isVisible = element.style.display === 'none' ? false : true;
    if (!isVisible) {
      element.style.display = 'block';
      if (bodyElement) {
        bodyElement.classList.add('overflow-hidden');
        const sideNavOverlay$: any =
          document.getElementsByClassName('sidenav-overlay')[0];
        sideNavOverlay$.style.display = 'block';
      }
    } else {
      element.style.display = 'none';
      if (bodyElement) {
        bodyElement.classList.remove('overflow-hidden');
        const sideNavOverlay$: any =
          document.getElementsByClassName('sidenav-overlay')[0];
        sideNavOverlay$.style.display = 'none';
      }
    }
    /* }, 10); */
  }
}
