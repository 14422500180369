import { EncDecService } from './../../../services/encryption-decryption.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProfileService, DataService, UtilsService } from '../../../services';
import { PasswordPolicy, PasswordPolicyService } from 'src/app/modules/shared/services/password-policy.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  formSubmitted = false;
  userDetails;
  changePasswordForm: FormGroup;
  passwordPolicyData: PasswordPolicy;
  passwordPolicySub: Subscription;
  txtValue:string = null;
  message : string;

  constructor(private profileService: ProfileService,
              public dataService: DataService,
              private util: UtilsService,
              private passwordPolicyService: PasswordPolicyService,
              private encDecService: EncDecService) { }

  ngOnInit(): void {
    this.initForm();
    this.userDetails = this.dataService.userDetails;
    this.addPatternToNewPassword();
    this.validatePasswordPolicy();
  }

  initForm(): void {
    this.changePasswordForm = new FormGroup({
      currentPassword: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
    });
  }

  addPatternToNewPassword() {
    this.passwordPolicyService.getPasswordPolicy().subscribe(res => {
      const dataKey = 'data';
      if (res[dataKey].length > 0) {
        this.passwordPolicyData = {
          ...res[dataKey][0],
          is_min_password_length: false,
          is_alphabetical: false,
          is_numeric: false,
          is_special_chars: false
        };
        const start = /^/;
        const alpha = /(?=.*?[A-Z])(?=.*?[a-z])/;
        const numeric = /(?=.*?[0-9])/;
        const special = /(?=.*?[!@#$&*])/;
        const length = `/.{${this.passwordPolicyData.min_password_length},20}$/`;
        let pattern = start.source;

        if (this.passwordPolicyData.alphabetical === 1) {
          pattern += alpha.source;
        }

        if (this.passwordPolicyData.numeric === 1) {
          pattern += numeric.source;
        }

        if (this.passwordPolicyData.special_chars === 1) {
          pattern += special.source;
        }
        pattern += eval(length).source;
        this.changePasswordForm.get('newPassword').setValidators([Validators.pattern(pattern)]);
      }
    });
  }

  get formControls(): any {
    return this.changePasswordForm.controls;
  }

  updatePassword(): void {
    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {
        return;
    }
    let formObj = this.changePasswordForm.value;
    formObj.currentPassword = this.encDecService.set(formObj.currentPassword);
    formObj.newPassword = this.encDecService.set(formObj.newPassword);

    let userid = (this.userDetails.user_id).toString();
    const userId = this.encDecService.set(userid);
    console.log("userid in string", userId);
    console.log("userid in number", this.userDetails.user_id); 
    this.profileService.updatePassword(userId, formObj)
    .subscribe(() => {
        this.formSubmitted = false;
        this.changePasswordForm.reset();
        this.changePasswordForm.markAsUntouched();
        this.util.showSuccessToast('Password Updated Successfully');
        this.passwordPolicyData.is_min_password_length = false;
        this.passwordPolicyData.is_alphabetical = false;
        this.passwordPolicyData.is_numeric = false;
        this.passwordPolicyData.is_special_chars = false;
    }, (error) => {
        console.log(error);
    });
  }

  validatePasswordPolicy() {
    this.passwordPolicySub = this.changePasswordForm.get('newPassword').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      map(res => {
        if (res) {
          this.validatePassword(res);
        }
      })
    ).subscribe();
  }

  onTextChange(value)
  {

    this.txtValue = value;
    if(this.txtValue == '')
    {
      this.passwordPolicyData.is_min_password_length = false;
        this.passwordPolicyData.is_alphabetical = false;
        this.passwordPolicyData.is_numeric = false;
        this.passwordPolicyData.is_special_chars = false;
    }

  }

  validatePassword(res: any) {
    const passwordPolicyData = this.passwordPolicyData;
    const start = /^/;
    const alpha = /(?=.*?[A-Z])(?=.*?[a-z])/;
    const numeric = /(?=.*?[0-9])/;
    const special = /(?=.*?[!@#$&*])/;
    const length = /.{6,20}$/;

    if ((passwordPolicyData.min_password_length <= res.length)) {
      this.passwordPolicyData.is_min_password_length = true;
    } else {
      this.passwordPolicyData.is_min_password_length = false;
    }

    if ((passwordPolicyData.alphabetical === 1 && alpha.test(res))) {
      this.passwordPolicyData.is_alphabetical = true;
    } else {
      this.passwordPolicyData.is_alphabetical = false;
    }

    if ((passwordPolicyData.numeric === 1 && numeric.test(res))) {
      this.passwordPolicyData.is_numeric = true;
    } else {
      this.passwordPolicyData.is_numeric = false;
    }

    if ((passwordPolicyData.special_chars === 1 && special.test(res))) {
      this.passwordPolicyData.is_special_chars = true;
    } else {
      this.passwordPolicyData.is_special_chars = false;
    }
  }

  ngOnDestroy(): void {
    if (this.passwordPolicySub) {
      this.passwordPolicySub.unsubscribe();
    }
  }

}
