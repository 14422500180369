export interface ModalState {
    open: boolean;
    position: ModalPosition;
  }

export enum ModalPosition {
    CENTER = 'CENTER',
    RIGHT = 'RIGHT',
  }

export enum ModalType {
    HOME = 'HOME',
    SECONDARY_1 = 'SECONDARY_1',
    SECONDARY_2 = 'SECONDARY_2',
    HIERARCHY = 'HIERARCHY',
    USER = 'USER',
    USER_EDIT = 'USER_EDIT',
    FACILITY_ADD = 'FACILITY_ADD',
    FACILITY_EDIT = 'FACILITY_EDIT',
    LOCATION_ADD = 'LOCATION_ADD',
    LOCATION_EDIT = 'LOCATION_EDIT',
    ROLE_ADD = 'ROLE_ADD',
    ROLE_EDIT = 'ROLE_EDIT',
    BIRTH_RATE = 'BIRTH_RATE',
    SITE_ADD = 'SITE_ADD',
    SITE_EDIT = 'SITE_EDIT',
    MATERIAL_ADD = 'MATERIAL_ADD',
    MATERIAL_EDIT = 'MATERIAL_EDIT',
    MATERIAL_LOCATION_LINK_ADD = 'MATERIAL_LOCATION_LINK_ADD',
    MATERIAL_RECEIPT_ADD = 'MATERIAL_RECEIPT_ADD',
    SESSION_ALLOCATION_ADD = 'SESSION_ALLOCATION_ADD',
    SESSION_ALLOCATION_EDIT = 'SESSION_ALLOCATION_EDIT',
    TEMPLATE_ADD = 'TEMPLATE_ADD',
    TEMPLATE_EDIT = 'TEMPLATE_EDIT',
    BACKLOG_VACCINATION_FORM = 'BACKLOG_VACCINATION_FORM',
    MIGRATE_BENEFICIARY_FORM = 'MIGRATE_BENEFICIARY_FORM',
    GPS_LOCATION = 'GPS_LOCATION'
  }
