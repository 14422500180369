import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { ModalPosition, ModalType } from 'src/app/modules/generic-popup/model';
import { GenericPopupService } from 'src/app/modules/generic-popup/services/generic-popup.service';
import { AuthService } from 'src/app/screens/auth/services/auth.service';
import { PatientRegisterService } from 'src/app/screens/patient-registration/services/patient-register.service';
import { DataService, ProfileService } from '../../services';
import { TokenTrackerService } from 'src/app/screens/auth/services/token-tracker.service';
import { log } from 'console';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @ViewChild('dropdown') dropDown: BsDropdownDirective;
  isUserLoggedIn = false;
  public languageHead: any;
  userDetails: any;
  profilePic: any;
  currUrl = '';
  NotificationCount: any ;
  allNotification: any;
  constructor(public auth: AuthService,
              private router: Router,
              private genericPopupService: GenericPopupService,
              public dataService: DataService,
              private profileService: ProfileService,
              private patientRegisterService: PatientRegisterService
              ) {
                this.router.events.subscribe(val => {
                  if (val instanceof NavigationEnd) {
                    this.currUrl = (val.url !== val.urlAfterRedirects) ? val.urlAfterRedirects : val.url;
                  }
                });
                this.auth.currentUser.subscribe( r => this.isUserLoggedIn = r);
              }

  ngOnInit(): void {
    if (this.auth.currentUserValue) {
      this.userDetails = this.dataService.userDetails;
      let payload ={user_id: this.userDetails.user_id}
      this.patientRegisterService.getNotificationsCount(payload).subscribe(res=>{
      this.NotificationCount=res
      //console.log("countis", this.NotificationCount.count);
      this.getTokenExpiryTime();
      
  })
    this.patientRegisterService.getAllNotifications(payload).subscribe(res=>{ 
      this.allNotification = res['data']
  })

    }
  }

  editCount(){
    let payload ={user_id: this.userDetails.user_id}
    this.patientRegisterService.setNotificationsRead(payload).subscribe(res=>{ 
      // this.NotificationCount = res
      this.patientRegisterService.getNotificationsCount(payload).subscribe(res=>{
        this.NotificationCount=res
      })
  })
  }
 
  openProfile(): void {
    
    this.genericPopupService.open(ModalPosition.CENTER, ModalType.SECONDARY_1);
    // this.toggle();
  }

  openHierachy(){
    
    this.genericPopupService.open(ModalPosition.CENTER, ModalType.HIERARCHY);
  }

 

  excludeHeader(str): boolean {
     return (str === '' || /login/g.test(str));
  }

  logout(): void {
    this.auth.logout();
  }

  sidebarToggleHeader() {
    const body$ = document.getElementsByTagName('body')[0];
    const sidebarPopup$ = document.getElementsByClassName('sidebar-popup')[0];
    this.fadeInOutEffectHeader(sidebarPopup$, body$);
  }

  fadeInOutEffectHeader(element, bodyElement?) {
   /* setTimeout(() => { */
      const isVisible = element.style.display === 'none' ? false : true;
      if (!isVisible) {
        element.style.display = 'block';
        if (bodyElement) {
          bodyElement.classList.add('overflow-hidden');
          const sideNavOverlay$: any = document.getElementsByClassName('sidenav-overlay')[0];
          sideNavOverlay$.style.display = 'block';
        }
      } else {
        element.style.display = 'none';
        if (bodyElement) {
          bodyElement.classList.remove('overflow-hidden');
          const sideNavOverlay$: any = document.getElementsByClassName('sidenav-overlay')[0];
          sideNavOverlay$.style.display = 'none';
        }
      }
    /* }, 10); */
  }
  redirectToNotification(){
    this.router.navigate([`dashboard/notification`])
    
  }
  ngAfterViewInit() {
    /* document.addEventListener('DOMContentLoaded', (event) => { */
      /* const sliderClose$ = document.getElementsByClassName('btn-slider-close')[0];

      sliderClose$.addEventListener('click', (e) => {
        e.preventDefault();
        this.sidebarToggleHeader();
      }); */
    /* }); */
  }

  tokenExpireyTime: string;
  getTokenExpiryTime() {
    return this.auth.getTokenExpiryTime().subscribe((res: any) => {
      if (res) {
        this.tokenExpireyTime = res.data.expiryTime;
      }
    });
  }

  downloadManualPdf() {
    console.log('User Details', this.userDetails.name);
    
    const link = document.createElement('a');
  
    if (this.userDetails.name === 'Psychiatrist') {
      link.href = 'assets/pdfs/Pschiatrist v1 1.2.docx.pdf';
      link.download = 'Pschiatrist v1 1.2.docx.pdf';
    } else if (this.userDetails.name === 'Psychologist') {
      link.href = 'assets/pdfs/Psychologist v1 1.2.docx.pdf';
      link.download = 'Psychologist v1 1.2.docx.pdf';
    } else if (this.userDetails.name === 'Counsellor') {
      link.href = 'assets/pdfs/counsellor v1 1.2.docx.pdf';
      link.download = 'Counselor v1 1.2.docx.pdf';
    } else {
      console.log('User is not authorized to download the manual');
      return;
    }
  
    link.target = '_blank';
    link.click();
  }
  



}
