<!-- START : Page  -->
<div class="box">
    <div class="row">
        <div class="col-lg-12">
            <p-button label="Advance Filter" styleClass="p-button-outlined"></p-button>
            <div class="row" *ngIf="toDisplay">
               <div class="col-lg-3 col-md-3 col-sm-12 mt-2">
                <p-dropdown [options]="cities" [(ngModel)]="selectedCity" optionLabel="name"></p-dropdown>
               </div>
               <div class="col-lg-3 col-md-3 col-sm-12 mt-2">
                <p-dropdown [options]="cities" [(ngModel)]="selectedCity" optionLabel="name"></p-dropdown>
               </div>
               <div class="col-lg-3 col-md-3 col-sm-12 mt-2">
                <p-dropdown [options]="cities" [(ngModel)]="selectedCity" optionLabel="name"></p-dropdown>
               </div>
               <div class="col-lg-3 col-md-3 col-sm-12 mt-2">
                <p-dropdown [options]="cities" [(ngModel)]="selectedCity" optionLabel="name"></p-dropdown>
               </div>
               <div class="col-lg-3 col-md-3 col-sm-12 mt-2">
                <p-dropdown [options]="cities" [(ngModel)]="selectedCity" optionLabel="name"></p-dropdown>
               </div>
               <div class="col-lg-3 col-md-3 col-sm-12 mt-2">
                <p-dropdown [options]="cities" [(ngModel)]="selectedCity" optionLabel="name"></p-dropdown>
               </div>
               <div class="col-lg-3 col-md-3 col-sm-12 mt-2">
                <p-dropdown [options]="cities" [(ngModel)]="selectedCity" optionLabel="name"></p-dropdown>
               </div>
               <div class="col-lg-3 col-md-3 col-sm-12 mt-2">
                <p-dropdown [options]="cities" [(ngModel)]="selectedCity" optionLabel="name"></p-dropdown>
               </div>
               <div class="col-lg-3 col-md-3 col-sm-12 mt-2">
                <p-dropdown [options]="cities" [(ngModel)]="selectedCity" optionLabel="name"></p-dropdown>
               </div>
               <div class="col-lg-3 col-md-3 col-sm-12 mt-2">
                <p-dropdown [options]="cities" [(ngModel)]="selectedCity" optionLabel="name"></p-dropdown>
               </div>
          
              </div>
        </div>
    </div>
    <div class="box-header pb-3 pt-3">
        <h4 class="box-title">
            <span class="">{{dataService.checkPermission('Patient Registration') === 'READ' ? 'View RPP Patients' :
                'RPP Patient Assignment'}}</span>
        </h4>
    </div>
    <!-- User statistics  -->

    <div class="box-body">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12"></div>
            <div class="col-md-8 ml-auto text-secondary">
                <div class="input-group mb-3 search-mob pull-right">
                    <input type="text" class="form-control" name="contact" maxlength="12"
                        (keydown.enter)="searchWithPhoneNumber()" [(ngModel)]="contactNumber"
                        placeholder="Name/Mobile No.">
                    <div class="input-group-append">
                        <button [ngClass]="{'btn-dark' : disableSearch, 'btn-primary': !disableSearch}"
                            class="btn btn-sm" type="button" (click)="searchWithPhoneNumber()"><i
                                class="pi pi-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
       

        <div class="row no-gutters grid-table-height">
            <div class="col">
                <app-commongrid #dataGrid [colModel]='cols' gridId='commonGrid' sField='updatedDate' sOrder='desc'
                    selectType='none' [localData]="[]" idColumn='userGrid' [rowsPerPage]='50' paginationpos='bottom'
                    (onCustomPageAndFilterEvent)="onPageChangeEvent($event)"
                    (onCustomFilterEvent)="onCustomFilter($event)">
                    <ng-template #actionTemplate let-gridData='griddata'>
                        <!-- *ngIf="dataService.addeditPermission('Patient List') && (gridData.status == 'Paid'||gridData.status == 'Patient Details Completed'||gridData.status == 'Assessments & Summary Completed'||gridData.status == 'Form 5 Completed'||gridData.status == 'Diagnosis Completed' || gridData.status == 'Treatment Form Completed'|| gridData.status == 'Session Completed')" -->

                        <button *ngIf="dataService.addeditPermission('RPP Patient Assignment') && (gridData.status == 'Paid') || 
                             (gridData.status == 'Pay Later') ||
                             (gridData.status == 'Assigned') || (gridData.status == 'Expired')"
                            (click)="editPatient(gridData)" class="butonclover" title="Edit">
                            <span class="actionicons iconscls text-warning"><i class="ti ti-pencil"></i></span>
                        </button>


                    </ng-template>
                    <ng-template #statuTemplate let-gridData='griddata'>
                        <ng-container *ngIf="gridData.status == 'Paid'">
                            <span class="badge badge-success">Paid</span>
                        </ng-container>
                        <ng-container *ngIf="gridData.status == 'Payment link Generated'">
                            <span class="badge badge-info">Payment link Generated</span>
                        </ng-container>
                        <ng-container *ngIf="gridData.status == 'Payment link Regenerated'">
                            <span class="badge badge-info">Payment link Regenerated</span>
                        </ng-container>
                        <ng-container *ngIf="gridData.status == 'Ordered'">
                            <span class="badge badge-success">Ordered</span>
                        </ng-container>
                        <ng-container *ngIf="gridData.status == 'Payment Failed'">
                            <span class="badge badge-danger">Payment Failed</span>
                        </ng-container>
                        <ng-container *ngIf="gridData.status == 'Pay Later'">
                            <span class="badge badge-danger">Pay Later</span>
                        </ng-container>
                        <ng-container *ngIf="gridData.status == 'Expired'">
                            <span class="badge badge-danger me-3">Expired</span>
                            <a href="#" class="" data-toggle="tooltip" (click)="redirectregisterpage(gridData)"
                                data-placement="top" title="Renew"><i class="pi pi-refresh"></i></a>
                        </ng-container>
                        <ng-container *ngIf="gridData.status == 'Payment link Expired'">
                            <span class="badge badge-danger">Payment link Expired</span>
                        </ng-container>
                        <ng-container *ngIf="gridData.status == 'Assigned'">
                            <span class="badge badge-inverse">Assigned</span>
                        </ng-container>
                    </ng-template>
                    <ng-template #payTemplate let-gridData='griddata'>
                        <ng-container *ngIf="gridData.payment_type_name == 'Pay Later'">
                            <span class="badge badge-danger">Pay Later</span>
                            <a href="#" class="me-3" data-toggle="tooltip" (click)="redirectregisterpage(gridData)"
                                data-placement="top" title="Update Payment"><img
                                    src="assets/images/hospital-icon/paylater.svg" height="34px"></a>
                        </ng-container>
                        <ng-container *ngIf="gridData.payment_type_name == 'Cash'">
                            <span class="badge badge-success">Cash</span>

                        </ng-container>
                        <ng-container *ngIf="gridData.payment_type_name == 'Online'">
                            <span class="badge badge-success">Online</span>
                        </ng-container>
                    </ng-template>
                </app-commongrid>
               

            </div>

        </div>

       
    </div>



</div>
<!-- END : Page  -->



<!-- <div class="idcopy" *ngIf="copyDataShow">
    <h4>Patient unique Id </h4>
    <input [value]="refId" disabled />
    <button [cdkCopyToClipboard]="refId" (click)="dataCopy()">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px"
            viewBox="0 0 488.3 488.3" width="14px" height="14px" xml:space="preserve">
            <g>
                <path
                    d="M314.25,85.4h-227c-21.3,0-38.6,17.3-38.6,38.6v325.7c0,21.3,17.3,38.6,38.6,38.6h227c21.3,0,38.6-17.3,38.6-38.6V124    C352.75,102.7,335.45,85.4,314.25,85.4z M325.75,449.6c0,6.4-5.2,11.6-11.6,11.6h-227c-6.4,0-11.6-5.2-11.6-11.6V124    c0-6.4,5.2-11.6,11.6-11.6h227c6.4,0,11.6,5.2,11.6,11.6V449.6z" />
                <path
                    d="M401.05,0h-227c-21.3,0-38.6,17.3-38.6,38.6c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5c0-6.4,5.2-11.6,11.6-11.6h227    c6.4,0,11.6,5.2,11.6,11.6v325.7c0,6.4-5.2,11.6-11.6,11.6c-7.5,0-13.5,6-13.5,13.5s6,13.5,13.5,13.5c21.3,0,38.6-17.3,38.6-38.6    V38.6C439.65,17.3,422.35,0,401.05,0z" />
            </g>
        </svg>
    </button>

    <p *ngIf="copy" class="copyd">Copy</p>

</div> -->