import { Component, Host, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Colmodel, GridFIlterType } from 'src/app/modules/commongrid/colmodel.model';
import { CommongridComponent } from 'src/app/modules/commongrid/commongrid.component';

import { DataService, ProfileService, UtilsService } from 'src/app/modules/shared/services';
import { AuthService } from '../auth/services/auth.service';

import { DialogService } from 'primeng/dynamicdialog';
import { PatientRegisterService } from '../patient-registration/services/patient-register.service';
import { RppRenewListService } from './rpp-renew-list.service';


@Component({
  selector: 'app-rpp-renew-list',
  templateUrl: './rpp-renew-list.component.html',
  styleUrls: ['./rpp-renew-list.component.scss']
})
export class RppRenewListComponent implements OnInit{

  globalPayLoad;
  cols: Colmodel[] = [];
  currentPage = 1;
  disableSearch = true;
  disableColumn
  dataSource
  patinetname
  userInfo
  gridData
  showExpiredbutton = false
  public contactNumber: any = '';
  @ViewChild(CommongridComponent) dataGrid: CommongridComponent;
  @ViewChild('actionTemplate', {static: true}) actionTemplate;
  @ViewChild('statuTemplate', {static: true}) statuTemplate;
  @ViewChild('payTemplate', {static: true}) payTemplate;
  constructor(public dataService: DataService,
              public renewService : RppRenewListService,
              public authService: AuthService,
              public route: Router,
              public utilsService: UtilsService,
              private profileService: ProfileService,
              private patientRegisterService: PatientRegisterService) { 
      
                const navigation = this.route.getCurrentNavigation();
      const state = navigation.extras.state;
      
      if (!state) {
        this.route.navigate(['/rpp-renew-patient-list']);
        return;
      }
      this.gridData = JSON.parse(state.editData);
      console.log("grid data is",this.gridData);
      
     }

  ngOnInit(): void {

    this.profileService.getLoggedInUserInfo().subscribe((res)=>{
      this.userInfo = res
      console.log("data is", this.userInfo);
      if(this.userInfo.name == 'CRM Counsellor' || this.userInfo.name == 'Counsellor'){
        this.showExpiredbutton = true;
      }
    })

    if(this.dataService.userDetails.name == 'Receptionist'){
      this.disableColumn = true;
    }
    else{
      this.disableColumn = false;
    }
    this.cols = [

      new Colmodel('serial_num', 'Sr. No', false, true, false, { filtertype: GridFIlterType.INPUT }),
      new Colmodel('patient_ref_id', 'Patient Id', false, true, false, { filtertype: GridFIlterType.INPUT }),
      new Colmodel('patient_id', 'Id', false, true, true, {filtertype: GridFIlterType.INPUT}),
      new Colmodel('patient_name', 'Patient Name', false, true, false, {filtertype: GridFIlterType.INPUT}),
      new Colmodel('mobile_number', 'Mobile Number', false, true, false, {filtertype: GridFIlterType.INPUT}),
      new Colmodel('package_name', 'Plan Name', false, true, false, {filtertype: GridFIlterType.INPUT}),
      new Colmodel('enrollment_date', 'Enrollment Date', false, true, false, {filtertype: GridFIlterType.INPUT}),
      new Colmodel('due_date', 'Due Date', false, true, false, {filtertype: GridFIlterType.INPUT}),
      // new Colmodel('service_details', 'Service Assigned', false, true, false, {filtertype: GridFIlterType.INPUT}),
       new Colmodel('payment_type_name', 'Payment Status', false, true, false, {filtertype: GridFIlterType.INPUT, formatterTemplateRef: this.payTemplate}),
      new Colmodel('status', 'Status', false, true, false, {filtertype: GridFIlterType.INPUT, formatterTemplateRef: this.statuTemplate}),
      // new Colmodel('action', 'Actions', false, false, this.disableColumn, {filtertype: GridFIlterType.NONE, formatterTemplateRef: this.actionTemplate}),
    ];
    
    this.getPatientGridData()
  }

  getPatientGridData() {
    let expiredata = false
    if(this.gridData == 'expire'){
      expiredata = true
      this.noteinfo = "Note: RPP Package expire in last 7 days"
    }
    const tempPayload = {
      page_size: this.dataGrid && this.dataGrid.rows ? this.dataGrid.rows : 50,
      current_page: this.currentPage,
      expire_last_week : expiredata
    };
    this.renewService
      .getPatientRPPRenewGridDetails(tempPayload)
      .subscribe((res: any) => {
        this.dataGrid.data = res.data;
        this.dataGrid.totalRecords = res.count;
        
      });
  }

  keyPressNumbers(event){
    var charCode = (event.which) ? event.which : event.keyCode;
    this.disableSearch =  this.contactNumber.length >= 9 ? false : true;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onFocusOutEvent(event){
    this.disableSearch =  this.contactNumber.length >= 10 ? false : true;
    if (this.disableSearch) {
      this.getPatientGridData()
    }
  }
  noteinfo
  searchWithPhoneNumber(){
    if(this.contactNumber == ""){
      this.getPatientGridData()
    }

    let expiredata = false
    if(this.gridData == 'expire'){
      expiredata = true;
      
    }
    let payload =
    {
      registration_source : 2,
      search_keyword : this.contactNumber,
      only_expired : true,
      expire_last_week : expiredata
    }
    this.patientRegisterService.findbyMobileNumber(payload).subscribe((res: any)=>{

      res.data.forEach((data: any, index) => {
        let srnumbers = index + 1
        data.serial_num = srnumbers;
      });

      this.dataGrid.data = res.data;
      this.dataGrid.totalRecords = res.count;
    })
  }

  editPatient(gridData) {
    const navigationExtras: NavigationExtras = {
      state: { editData: JSON.stringify(gridData) },
    };    
    this.route.navigate([`/rpp-assignment/assign-rpp/${gridData.patient_id}`], navigationExtras)
  }
  redirectregisterpage(gridData){
    const navigationExtras: NavigationExtras = {
      state: { editData: JSON.stringify(gridData) },
    };    
    
    this.route.navigate([`ipd-registration`], navigationExtras)
  }
  onCustomFilter(event) {
    this.dataGrid.totalRecords = event.data.length;
  }

  onPageChangeEvent(event) {
    this.currentPage = event.first == 0 ? 1 : (event.first/event.rows) + 1;
    this.dataGrid.rows = event.rows;
    // const payload = {filters: event.filters};
    const payload = {limit: true};
    this.getPatientGridData();
  }
}
