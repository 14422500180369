<!-- START : Page  -->

  <div class="page__card position-relative error-page">
    <div>
      <div>
        <div class="page-not-found-modal no-access">
          <!-- <h1 style="text-align: center;">
            401
          </h1> -->
          <div style="text-align: center; padding: 0px 0px 16px">
            <img class="ih" src="assets/images/401.png">
          </div>
          <h3 style="text-align: center; ">
            You are unauthorized to access this page.<br>
            Please try after sometime or try to logout and log in again.
          </h3>
          
        </div>
      </div>
    </div>
  </div>

<!-- END : Page  -->